import type { ParentProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./ContentTableContainer.module.css";

interface Props extends ParentProps {
  class?: string;
  modifier?: "solo";
}

export const ContentTableContainer = (props: Props) => (
  <section
    class={classNames(
      styles["content-table-container"],
      props.modifier ? styles[`content-table-container--${props.modifier}`] : "",
      props.class,
    )}
  >
    {props.children}
  </section>
);
