import { photoURL } from "@repo/client";
import type { Component } from "solid-js";
import { twMerge } from "tailwind-merge";
import { getUserAvatarPath } from "~/lib/avatars";

/**
 * extractInitials Extracts the initials from a name. It takes the first and last initials if there's more than
 * one part. If there's only one part, it takes the first two initials. If there's no name, it returns '??'.
 *
 * @param name
 */
export const extractInitials = (name: string): string => {
  if (!name) {
    return "??";
  }

  const parts = name.trim().split(/\s+/).filter(Boolean);
  let initials = parts.slice(0, 2).map((part) => (part[0] ? part[0].toUpperCase() : ""));

  // If there's more than one part, take the first and last part's initial
  if (initials.length > 1 && initials[0] && initials[initials.length - 1]) {
    initials = [initials[0], initials[initials.length - 1] ?? ""];
  }

  return initials.join("");
};

export enum CircularAvatarSize {
  Default = 0,
  Micro = 1,
  Mini = 2,
}

export type CircularAvatarProps = {
  size: CircularAvatarSize;
  userId?: string;
  src?: string;
  fullName?: string;
  class?: string;
};

export const CircularAvatar: Component<CircularAvatarProps> = (props) => {
  return (
    <span
      class={twMerge(
        "m-1 ring-2 ring-white inline-flex items-center justify-center rounded-full bg-gray-500 relative",
        props.class,
      )}
      classList={{
        "w-6 h-6": props.size === CircularAvatarSize.Micro,
        "w-9 h-9": props.size === CircularAvatarSize.Mini,
        "w-12 h-12": props.size === CircularAvatarSize.Default,
      }}
    >
      <span class="absolute text-xs font-medium leading-none text-white">
        {extractInitials(props.fullName ?? "Guest")}
      </span>
      <img
        referrerpolicy="no-referrer"
        class="absolute inline-block rounded-full"
        classList={{
          "w-6 h-6": props.size === CircularAvatarSize.Micro,
          "w-9 h-9": props.size === CircularAvatarSize.Mini,
          "w-12 h-12": props.size === CircularAvatarSize.Default,
        }}
        src={`${getUserAvatarPath(props.userId, props.src) ?? photoURL.DefaultGuestPhotoRelPath}?fallback`}
        // @ts-expect-error
        onerror="this.style.display='none'"
        alt={props.fullName || "Avatar"}
      />
    </span>
  );
};
