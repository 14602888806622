/*
 * Consistent reference to element used for attaching & dispatching.
 */
const element = () => globalThis.document.documentElement;

/*
 * Local function to ensure dispatching of custom events is done on the body element.
 */
const dispatch = (event: CustomEvent) => element().dispatchEvent(event);

/*
 * Reference for naming of custom events.
 */
const GLOBAL_EVENT_NAMES = {
  uploadComplete: "asset_upload_complete",
} as const;

/*
 * Store for attaching & removing custom DOM event listeners.
 */
export const CustomEvents = {
  uploadComplete: {
    add: (listener: () => void) => element().addEventListener(GLOBAL_EVENT_NAMES.uploadComplete, listener),
    remove: (listener: () => void) => element().removeEventListener(GLOBAL_EVENT_NAMES.uploadComplete, listener),
    dispatch: () => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.uploadComplete, {
        bubbles: false,
        cancelable: false,
      });
      dispatch(event);
    },
  },
} as const;
