import { useNavigate } from "@solidjs/router";
import { OcPluscircle2 } from "solid-icons/oc";
import { TbArrowsMove, TbEdit, TbTrash } from "solid-icons/tb";
import { Portal } from "solid-js/web";
import { ContextMenu, type ContextMenuProps } from "~/components/context-menu/ContextMenu";
import type { ContextMenuItemProps } from "~/components/context-menu/ContextMenuItem";
import { urls } from "~/lib/urls";
import { CreateCollectionModal } from "~/screens/modals/CreateCollectionModal";
import { DeleteCollectionModal } from "~/screens/modals/DeleteCollectionModal";
import { MoveCollectionModal } from "~/screens/modals/MoveCollectionModal";
import { RenameCollectionModal } from "~/screens/modals/RenameCollectionModal";
import { useUIState } from "~/ui/UIState";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import { useWire } from "~/wire";
import styles from "./CollectionsContextMenu.module.css";

type Props = Omit<ContextMenuProps, "items" | "signal"> & {
  onCollectionCreated: (label: string) => void;
  collectionId: string;
};

export const CollectionsContextMenu = (props: Props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const state = useUIState();
  const signal = state.collectionsContextMenu;
  const col = () => wire.services.collections.getCollection(props.collectionId);

  const rootCollection = () => wire.services.collections.getPersonalRoot();
  const rootCollectionId = rootCollection()?.id;
  const currentParentId = wire.services.collections.getParentsIds(props.collectionId)[0];

  const rootHasOptions = () => {
    if (rootCollectionId) {
      const rootChildren = wire.services.collections.getChildrenIds(rootCollectionId);
      const filteredRootChildren = rootChildren.filter((entry) => entry !== props.collectionId);
      const rootHasParent = rootChildren.includes(currentParentId ?? "");
      if (filteredRootChildren.length > 0 || (rootHasParent && sourceParentHasOptions())) {
        return true;
      }
    }
    return false;
  };

  const sourceParentHasOptions = () => {
    if (currentParentId) {
      const parentChildren = wire.services.collections
        .getChildrenIds(currentParentId)
        .filter((entry) => entry !== props.collectionId);
      if (parentChildren.length > 0) {
        return true;
      }
    }
    return false;
  };

  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  const [, setCollectionsContextOpen] = state.collectionsContextMenu;

  const CreateCollectionModalContents = () => (
    <CreateCollectionModal
      id="create-collection-modal"
      parentLabel={col()?.label || ""}
      onCreate={async (name, description) => {
        const res = await wire.services.collections.apiCreateCollection(
          wire.services.collections.getDataOrFail(props.collectionId),
          name,
          description,
        );
        setModalOpen("");
        setModalContents(null);
        navigate(urls.collection(res.data.id));
        props.onCollectionCreated(name);
      }}
    />
  );

  const RenameCollectionModalContents = () => (
    <RenameCollectionModal
      id="rename-collection-modal"
      name={col()?.label || ""}
      description={col()?.description || ""}
      onUpdate={async (name, description) => {
        await wire.services.collections.apiUpdateCollection(props.collectionId, name, description);
        setModalOpen("");
        setModalContents(null);
      }}
    />
  );

  const MoveCollectionModalContents = () => (
    <MoveCollectionModal
      id="move-collection-modal"
      label={col()?.label || ""}
      collectionId={props.collectionId}
      rootHasOptions={rootHasOptions()}
      rootCollectionId={rootCollectionId ?? ""}
      sourceParentHasOptions={sourceParentHasOptions()}
      sourceParentId={wire.services.collections.getParentsIds(props.collectionId)[0] ?? ""}
      onMove={async (parentCollectionId) => {
        return wire.services.collections.apiMoveCollection(props.collectionId, parentCollectionId);
      }}
    />
  );

  const DeleteCollectionModalContents = () => (
    <DeleteCollectionModal
      id="delete-collection-modal"
      label={col()?.label || ""}
      onDelete={async () => {
        navigate(urls.collection(wire.services.collections.getPersonalRoot()?.id ?? ""));
        await wire.services.collections.apiDeleteCollection(
          wire.services.collections.getDataOrFail(props.collectionId),
        );
        setModalOpen("");
        setModalContents(null);
      }}
    />
  );

  const createItems = (): ContextMenuItemProps[] => {
    const isUserCollection = col()?.label !== "Personal Collection";

    const create: ContextMenuItemProps = {
      divider: isUserCollection,
      label: "Create sub-Collection",
      icon: OcPluscircle2,
      variant: "primary",
      onClick: async () => {
        setCollectionsContextOpen("");
        setModalContents(() => CreateCollectionModalContents);
        setModalOpen("create-collection-modal");
      },
    };

    const rename: ContextMenuItemProps = {
      divider: false,
      label: "Rename",
      icon: TbEdit,
      onClick: async () => {
        setCollectionsContextOpen("");
        setModalContents(() => RenameCollectionModalContents);
        setModalOpen("rename-collection-modal");
      },
    };
    const move: ContextMenuItemProps = {
      divider: isUserCollection,
      label: "Move",
      icon: TbArrowsMove,
      onClick: async () => {
        setCollectionsContextOpen("");
        setModalContents(() => MoveCollectionModalContents);
        setModalOpen("move-collection-modal");
      },
    };
    const remove: ContextMenuItemProps = {
      divider: false,
      label: "Delete",
      icon: TbTrash,
      variant: "destructive",
      onClick: async () => {
        setCollectionsContextOpen("");
        setModalContents(() => DeleteCollectionModalContents);
        setModalOpen("delete-collection-modal");
      },
    };

    //  We need to determine if there is a valid parent to move to in order to exclude invalid options.
    if (isUserCollection) {
      if (currentParentId === rootCollectionId && !rootHasOptions()) {
        return [create, rename, remove];
      }
      return [create, rename, move, remove];
    }
    return [create];
  };

  return (
    <Portal mount={document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}>
      <ContextMenu
        class={styles["collections-context-menu"]}
        bound={props.bound}
        id={props.id}
        items={createItems()}
        signal={signal}
        placement="bottom-start"
      />
    </Portal>
  );
};
