import { type ParentProps, createEffect, on, onCleanup, onMount } from "solid-js";
import { resetContextMenus } from "~/components/context-menu/ContextMenu";
import { useUIState } from "~/ui/UIState";
import {
  closeAll,
  createIsDesktopListener,
  createIsMobileListener,
  createIsTabletListener,
} from "~/ui/global-listeners";

/**
 * Binds listener to parent DOM elements to capture clicks and manage overlays.
 *
 * **IMPORTANT**: This component should always be a child of our `UIState`.
 */
export const AppListeners = (props: ParentProps) => {
  onMount(() => {
    //  For every transition between mobile & desktop viewport sizes, we reset the context menus.
    const state = useUIState();
    if (state) {
      //  Temporary until we update our layouts across the app
      const [isTwoColumn] = state.isTwoColumn;
      createEffect(() => {
        if (!isTwoColumn()) {
          document.documentElement.classList.add("overflow");
        } else {
          document.documentElement.classList.remove("overflow");
        }
      });

      const [isMobile, setIsMobile] = state.isMobile;
      createEffect(
        on(
          () => [isMobile()],
          () => {
            resetContextMenus(state);
          },
        ),
      );
      const [, setIsTablet] = state.isTablet;
      const [, setIsDesktop] = state.isDesktop;

      //  Set Data attribute on the HTML element when we are in a mobile viewport size.
      const { isMobileQuery, isMobileListener } = createIsMobileListener(setIsMobile);
      if (isMobileQuery) {
        isMobileQuery.addEventListener("change", isMobileListener);
        onCleanup(() => isMobileQuery.removeEventListener("change", isMobileListener));
      }
      //  Set Data attribute on the HTML element when we are in a tablet viewport size.
      const { isTabletQuery, isTabletListener } = createIsTabletListener(setIsTablet);
      if (isTabletQuery) {
        isTabletQuery.addEventListener("change", isTabletListener);
        onCleanup(() => isTabletQuery.removeEventListener("change", isTabletListener));
      }
      //  Set Data attribute on the HTML element when we are in a desktop viewport size.
      const { isDesktopQuery, isDesktopListener } = createIsDesktopListener(setIsDesktop);
      if (isDesktopQuery) {
        isDesktopQuery.addEventListener("change", isDesktopListener);
        onCleanup(() => isDesktopQuery.removeEventListener("change", isDesktopListener));
      }

      //  Bind handlers to allow clicks and escape keys in the parent element to close overlays.
      const app = document.getElementById("app");
      if (app) {
        const { click, key } = closeAll();
        app.addEventListener("click", click);
        document.body.addEventListener("keydown", key);

        onCleanup(() => {
          app.removeEventListener("click", click);
          document.body.removeEventListener("keydown", key);
        });
      }
    }
  });
  return <>{props.children}</>;
};
