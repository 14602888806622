import type { Component } from "solid-js";

export const LandingHeroBackground: Component<{ heightClass?: string; class?: string }> = (props) => (
  <div class="pointer-events-none" aria-hidden>
    <div class={`absolute inset-0 bg-indigo-1100  z-[-1] ${props.class ?? ""}`}>
      <div class={`relative w-full ${props.heightClass ?? ""}`}>
        <div class="absolute inset-0 transform-gpu overflow-hidden" aria-hidden="true">
          <video
            autoplay
            playsinline
            muted
            loop
            src="/videos/hero-bg-blurred.mov"
            class="h-full w-full object-cover scale-150 origin-top bg-indigo-1100"
          />
        </div>
        <div
          aria-hidden="true"
          class="absolute left-0 right-0 top-0  transform-gpu overflow-hidden  bg-gradient-to-b from-indigo-1000 to-transparent h-full"
        />
        <div
          aria-hidden="true"
          class="absolute left-0 right-0 bottom-0 transform-gpu overflow-hidden bg-gradient-to-t from-[#070b24] to-transparent h-1/2"
        />
      </div>
    </div>
  </div>
);
