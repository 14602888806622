import { stAnalytics } from "@repo/analytics";
import type { curator } from "@repo/client";
import { pointerHover } from "@solid-primitives/pointer";
import { A } from "@solidjs/router";
import { For, Match, Show, Switch, createMemo, createSignal } from "solid-js";
import { Motion } from "@repo/solid-motionone";
import { twMerge } from "tailwind-merge";
import { CircularAvatar, CircularAvatarSize } from "~/components/_original/CircularAvatar";
import { CopyButton } from "~/components/_original/CopyButton";
import { urls } from "~/lib/urls";
import { useBreakpoints } from "~/lib/useBreakPoints";
import { useWire } from "~/wire";
import { truncatePromptArray } from "./truncatePromptArray";

// place this in code to avoid being tree-shaken
pointerHover;

// TODO @andi make new component for v2
export type TextInstructionUnitV1Props = {
  message: curator.MessagePromptV2;
  class?: string;
};

export const TextPromptUnitV1 = (props: TextInstructionUnitV1Props) => {
  const MAX_LEN = 340;
  const [ref, setRef] = createSignal<HTMLSpanElement>();
  const breakpoints = useBreakpoints();
  const wire = useWire();

  const [hovering, setHovering] = createSignal(false);
  const [seeAll, setSeeAll] = createSignal(false);

  // Collection mentions are sent to the server as [collection_id]
  // We need to replace them with the collection label when displaying the prompt in the thread
  const formatPrompt = createMemo(() => {
    const prompt = props.message.prompt;

    const collectionNames = wire.services.collections.getAllCollections().map((c) => ({ label: c.label, id: c.id }));
    const regex = new RegExp(`#(${collectionNames.map((c) => c.label).join("|")})`, "g");
    // Split prompt into segments based on collection mentions
    const segments = prompt
      .split(regex)
      .filter(Boolean) // Remove empty strings
      .map((segment) => {
        const collectionMatch = collectionNames.find((c) => c.label === segment);
        if (collectionMatch) {
          return { collectionId: collectionMatch.id, label: collectionMatch.label };
        }
        return { text: segment };
      }) as ({ text: string } | { collectionId: string; label: string })[];

    return segments.map((segment) => {
      if ("text" in segment) {
        return segment;
      }
      return { collection: `#${segment.label}`, collectionId: segment.collectionId };
    });
  });

  return (
    <div
      class={`relative ${!truncatePromptArray(formatPrompt(), MAX_LEN).hasTruncated ? "pb-6 flex items-center" : "pb-0"}`}
      use:pointerHover={setHovering}
    >
      <div class="absolute -top-2 md:-top-3 left-[-2.8125rem] md:left-[-4.6875rem] select-none pointer-events-none">
        <CircularAvatar
          userId={props.message.createdBy}
          size={breakpoints.md ? CircularAvatarSize.Default : CircularAvatarSize.Mini}
          fullName="Guest"
          class="ring-violet-500"
        />
      </div>
      <Motion.div
        // {...getUnitAnimationConfig()}
        class={twMerge("w-full relative dark:text-white md:pb-2 pl-2 md:pl-0 pr-2", props.class)}
      >
        <p
          ref={setRef}
          class="relative r-left-12 text-lg md:text-xl h-full leading-normal font-medium text-black dark:text-white"
        >
          <For each={seeAll() ? formatPrompt() : truncatePromptArray(formatPrompt(), MAX_LEN).prompt}>
            {(segment) => (
              <Switch>
                <Match when={"text" in segment && segment.text}>{(s) => <span>{s()}</span>}</Match>
                <Match when={"collection" in segment && segment}>
                  {(s) => (
                    <A class="text-blue-700 dark:text-blue-400 underline" href={urls.collection(s().collectionId)}>
                      {s().collection}
                    </A>
                  )}
                </Match>
              </Switch>
            )}
          </For>

          <Show when={truncatePromptArray(formatPrompt(), MAX_LEN).hasTruncated}>
            <button
              type="button"
              onClick={() => setSeeAll((s) => !s)}
              class="text-sm leading-normal text-violet-500 dark:text-violet-400 selection:text-white ml-1 select-none"
            >
              {seeAll() ? "See less" : "See more"}
            </button>
          </Show>
        </p>

        <Show when={hovering()}>
          <div class="absolute bottom-1 right-1 flex items-center gap-1">
            <CopyButton
              label="Copy Prompt"
              onClick={() => {
                stAnalytics.track("thread_prompt_copied", undefined);
              }}
              content={formatPrompt()
                .map((segment) => ("text" in segment ? segment.text : segment.collection))
                .join("")}
            />
          </div>
        </Show>
      </Motion.div>
    </div>
  );
};
