import { stAnalytics } from "@repo/analytics";
import { TbCircleCheck } from "solid-icons/tb";
import { onMount } from "solid-js";
import { Motion } from "@repo/solid-motionone";
import { StIcon } from "~/components/icons";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { GradientBorder } from "~/domains/chat/prompt/components/EditorFocusedAnimation";
import { SignUpCTA } from "~/domains/identity/components/SignUpCTA";
import { useWire } from "~/wire";

export const InsertSignUpUnit = () => {
  const wire = useWire();
  const { threadEventProps } = useThreadEventProperties();

  const interactionsCount = wire.services.limiting.guest.interactionsCount;

  onMount(() => {
    stAnalytics.track("thread_inserts_sign_up_shown", {
      ...threadEventProps(),
      interactionsCount: interactionsCount(),
    });
  });
  return (
    <Motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
      <div class="relative px-10 py-16 mt-10 rounded shadow-lg bg-white dark:bg-indigo-1100 dark:text-white flex justify-between items-center hover:shadow-glow-lg">
        <div>
          <p class="font-semibold mb-4 text-xl">Want to see past threads and save your progress? Create an account.</p>
          <div class="text-slate-700 dark:text-slate-300">
            <p class="mb-2 text-base leading-normal">Our promise to you:</p>
            <ul>
              <li class="flex items-center gap-3 ml-2 mb-2 text-base leading-normal">
                <StIcon icon={TbCircleCheck} />
                It's free to create an account
              </li>
              <li class="flex items-center gap-3 ml-2 mb-2 text-base leading-normal">
                <StIcon icon={TbCircleCheck} />
                Your data is 100% yours
              </li>
              <li class="flex items-center gap-3 ml-2 mb-2 text-base leading-normal">
                <StIcon icon={TbCircleCheck} />
                No models are trained on your data
              </li>
            </ul>
          </div>
        </div>

        <div class="relative z-10 animate-bounce">
          <SignUpCTA
            class="font-semibold text-base leading-normal px-10 bg-violet-800 hover:bg-violet-700 dark:bg-white dark:hover:bg-slate-100 text-white dark:text-slate-900 text-nowrap"
            tracking={() => {
              stAnalytics.track("thread_inserts_sign_up_clicked", {
                ...threadEventProps(),
                interactionsCount: interactionsCount(),
              });
              stAnalytics.track("sign_up_clicked", undefined);
            }}
          />
        </div>
        <GradientBorder />
      </div>
    </Motion.div>
  );
};
