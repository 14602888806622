import type { projects } from "@repo/client";
import { TbFolderFilled } from "solid-icons/tb";
import {
  type Component,
  Show,
  Suspense,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  startTransition,
} from "solid-js";
import { CollectionAssetsTable } from "~/components/CollectionAssetTable";
import { LargeFileUpload } from "~/components/LargeFileUpload";
import { SectionHeader } from "~/components/SectionHeader";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { ChatFileUpload } from "~/domains/chat/prompt/components/ChatFileUpload";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { ProcessingReadableState, readableProcessingState } from "~/lib/ui/readableProcessingState";
import { useUIState } from "~/ui/UIState";

export const CollectionsAssetsScreen: Component<{
  collection: CollectionSnapshot | undefined;
  assets: projects.AssetSnapshot[];
  refreshAssets: () => void;
}> = (props) => {
  const state = useUIState();
  const [tab, setTab] = state.mainContentTab;

  let canPoll = false;
  let timeout: ReturnType<typeof setTimeout> | undefined;
  const poll = () => {
    if (
      props.assets.filter(
        (asset) =>
          readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Ready &&
          readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Failed,
      ).length > 0
    ) {
      startTransition(() => {
        props.refreshAssets();
      });
      if (canPoll) {
        timeout = globalThis.setTimeout(poll, 3000);
      }
    } else {
      timeout = undefined;
    }
  };

  createEffect(() => {
    if (tab() === "Assets" && !timeout) {
      poll();
    }
  });

  onMount(() => {
    poll();
    canPoll = true;
    onCleanup(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      canPoll = false;
    });
  });

  const [assetUploadModal, setAssetUploadModal] = createSignal(false);
  return (
    <Suspense>
      <ChatFileUpload
        context="project"
        open={assetUploadModal()}
        setOpen={setAssetUploadModal}
        onUploaded={() => {
          startTransition(() => {
            props.refreshAssets();
          });
          globalThis.setTimeout(poll, 2000);
          setTab("Assets");
        }}
      />
      <ContentContainer>
        <LargeFileUpload showUploadModal={() => setAssetUploadModal(true)} />
      </ContentContainer>
      <Show when={props.assets.length}>
        <ContentContainer>
          <SectionHeader title="All assets in this Collection" icon={TbFolderFilled} />
          <CollectionAssetsTable collectionName={props.collection?.label || ""} data={props.assets} />
        </ContentContainer>
      </Show>
    </Suspense>
  );
};
