import type { IconTypes } from "solid-icons";
import { TiTick } from "solid-icons/ti";
import { createSignal, Match, onCleanup, onMount, Show, splitProps, Switch } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./DestructiveCTA.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  icon?: IconTypes;
  inactive?: boolean;
  rounded?: boolean;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
}
type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

export const DestructiveCTA = (props: Props) => {
  const [confirm, setConfirm] = createSignal(false);
  const [local, rest] = splitProps(props, [
    "label",
    "accessiblePrefix",
    "accessibleSuffix",
    "icon",
    "class",
    "rounded",
    "inactive",
    "onClick",
  ]);
  onMount(() => {
    onCleanup(() => setConfirm(false));
  });
  return (
    <button
      {...rest}
      type={props.type ?? "button"}
      class={classNames(
        styles["destructive-cta"],
        local.rounded ? styles["destructive-cta--rounded"] : "",
        local.inactive ? styles["destructive-cta--inactive"] : "",
        local.class,
      )}
      tabIndex={0}
      disabled={local.inactive}
      onClick={(event: MouseEvent) => {
        if (!local.onClick) return;
        if (confirm()) {
          return local.onClick(event);
        }
        return setConfirm(true);
      }}
    >
      <Switch>
        <Match when={!confirm()}>
          <Show when={local.icon}>
            {/* biome-ignore lint/style/noNonNullAssertion: Not correctly identifying <Show> condition */}
            <StIcon icon={local.icon!} class={styles["destructive-cta__icon"]} size="1.25rem" />
          </Show>
          <Show when={local.accessiblePrefix}>
            <span class="screen-reader">{local.accessiblePrefix}</span>
          </Show>
          <span class={styles["destructive-cta__label"]}>{local.label}</span>
          <Show when={local.accessibleSuffix}>
            <span class="screen-reader">{local.accessibleSuffix}</span>
          </Show>
        </Match>
        <Match when={confirm()}>
          <StIcon icon={TiTick} class={styles["destructive-cta__icon"]} size="1.25rem" />
          <Show when={local.accessiblePrefix}>
            <span class="screen-reader">{local.accessiblePrefix}</span>
          </Show>
          <span class={styles["destructive-cta__label"]}>Yes, delete this Collection.</span>
          <Show when={local.accessibleSuffix}>
            <span class="screen-reader">{local.accessibleSuffix}</span>
          </Show>
        </Match>
      </Switch>
    </button>
  );
};
