import { createStore, produce } from "solid-js/store";
import type { SetThreadsStoreFunc, ThreadEntity, ThreadSnapshot, ThreadsStore } from "./threads.types";
import { type Accessor, batch, createResource } from "solid-js";
import { getRequestClient, type curator } from "@repo/client";
import type { CollectionsService } from "../collections/collections.service";

// ▗▄▄▖ ▗▄▄▄▖ ▗▄▖ ▗▄▄▖ ▗▄▄▄▖
// ▐▌     █  ▐▌ ▐▌▐▌ ▐▌▐▌
//  ▝▀▚▖  █  ▐▌ ▐▌▐▛▀▚▖▐▛▀▀▘
// ▗▄▄▞▘  █  ▝▚▄▞▘▐▌ ▐▌▐▙▄▄▖

const initialStore = (): ThreadsStore => ({
  ids: {},
  childOf: {},
});

/**
 * Creates a reactive threads store instance.
 *
 * Simple wrapper around solid's `createStore` for now, could hold extra initializing logic in the future.
 */
export const createThreadsStore = () => createStore<ThreadsStore>(initialStore());

// ▗▖  ▗▖▗▖ ▗▖▗▄▄▄▖ ▗▄▖ ▗▄▄▄▖▗▄▄▄▖ ▗▄▖ ▗▖  ▗▖ ▗▄▄▖
// ▐▛▚▞▜▌▐▌ ▐▌  █  ▐▌ ▐▌  █    █  ▐▌ ▐▌▐▛▚▖▐▌▐▌
// ▐▌  ▐▌▐▌ ▐▌  █  ▐▛▀▜▌  █    █  ▐▌ ▐▌▐▌ ▝▜▌ ▝▀▚▖
// ▐▌  ▐▌▝▚▄▞▘  █  ▐▌ ▐▌  █  ▗▄█▄▖▝▚▄▞▘▐▌  ▐▌▗▄▄▞▘

export const updateThreadEntity = (setStore: SetThreadsStoreFunc, entity: ThreadEntity) => {
  batch(() => {
    updateThread(setStore, entity.data);
    updateThreadParents(setStore, entity.data.threadId, entity.collectionIds);
  });
};

export const updateThreadEntitiesBatch = (setStore: SetThreadsStoreFunc, threads: ThreadEntity[]) => {
  batch(() => {
    for (const thread of threads) {
      updateThreadEntity(setStore, thread);
    }
  });
};

export const updateThreadParents = (setStore: SetThreadsStoreFunc, threadId: string, collections: string[]) =>
  setStore("childOf", threadId, collections);

export const updateThread = (setStore: SetThreadsStoreFunc, thread: ThreadSnapshot) =>
  setStore("ids", thread.threadId, thread);

export const updateThreadFromState = (setStore: SetThreadsStoreFunc, thread: curator.ThreadState) => {
  batch(() => {
    updateThread(setStore, {
      ...thread,
      createdAt: thread.metadata.createdAt,
      createdBy: thread.metadata.modifiedBy,
      modifiedAt: thread.metadata.modifiedAt,
      messageCount: thread.messages?.length || 0,
    });
    updateThreadParents(setStore, thread.threadId, thread.collectionIDs);
  });
};

export const updateThreadLabel = (setStore: SetThreadsStoreFunc, threadId: string, label: string) =>
  setStore("ids", threadId, "label", label);

export const updateRemoveThread = (setStore: SetThreadsStoreFunc, threadId: string) =>
  setStore(
    produce((s) => {
      delete s.ids[threadId];
      delete s.childOf[threadId];
    }),
  );

export const updateThreadsBatch = (setStore: SetThreadsStoreFunc, threads: ThreadSnapshot[]) => {
  batch(() => {
    for (const thread of threads) {
      updateThread(setStore, thread);
    }
  });
};

// ▗▄▄▖ ▗▄▄▄▖▗▄▄▄▖▗▄▄▄▖▗▄▄▄▖▗▄▄▖  ▗▄▄▖
// ▐▌   ▐▌     █    █  ▐▌   ▐▌ ▐▌▐▌
// ▐▌▝▜▌▐▛▀▀▘  █    █  ▐▛▀▀▘▐▛▀▚▖ ▝▀▚▖
// ▝▚▄▞▘▐▙▄▄▖  █    █  ▐▙▄▄▖▐▌ ▐▌▗▄▄▞▘

export const getThread = (store: ThreadsStore, threadId: string) => store.ids[threadId];
export const getThreadOrFail = (store: ThreadsStore, threadId: string) => {
  const thread = getThread(store, threadId);
  if (!thread) throw Error("Thread not found");
  return thread;
};
export const getThreadCollections = (store: ThreadsStore, threadId: string) => store.childOf[threadId];

//  ▗▄▖  ▗▄▄▖▗▖  ▗▖▗▖  ▗▖ ▗▄▄▖
// ▐▌ ▐▌▐▌    ▝▚▞▘ ▐▛▚▖▐▌▐▌
// ▐▛▀▜▌ ▝▀▚▖  ▐▌  ▐▌ ▝▜▌▐▌
// ▐▌ ▐▌▗▄▄▞▘  ▐▌  ▐▌  ▐▌▝▚▄▄▖

export const createFetchCollectionThreadsResource = (
  setThreadsStore: SetThreadsStoreFunc,
  updateCollections: CollectionsService["setCollections"],
  getAuthToken: () => string | undefined,
  isReady: () => boolean,
  collectionId: Accessor<string | undefined>,
) => {
  const client = getRequestClient(getAuthToken);
  const [data, methods] = createResource(
    () => (isReady() ? collectionId() : undefined),
    async (id) => {
      if (!id) return;
      const response = await client.controlplane.GetThreadsByCollectionID(id, {
        Limit: 10,
        Offset: 0,
        Sort: [],
      });
      const collections = Object.values(response.data.result.collections).map((c) => c.data);
      updateCollections(collections);
      updateThreadEntitiesBatch(setThreadsStore, response.data.result.entities);
      return response;
    },
  );

  return [data, methods] as const;
};
