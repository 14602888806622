import type { curator, operations } from "@repo/client";
import { TbArrowsMove, TbKeyframes } from "solid-icons/tb";
import { For, Match, Show, Suspense, Switch, createEffect, createSignal, onMount } from "solid-js";
import { CollectionsHierarchy } from "~/components/CollectionsHierarchy";
import type { CollectionsModalProps } from "~/components/containers/CollectionsModal";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";
import { ROOT_COLLECTION_NAMES, VisibleCollections } from "~/domains/collections/collections.ui";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import { ErrorModal } from "./ErrorModal";
import styles from "./MoveCollectionModal.module.css";

interface Props extends CollectionsModalProps {
  onMove: (parentCollectionId: string) => Promise<operations.Response<curator.CollectionState[]>>;
  label: string;
  sourceParentId: string;
  collectionId: string;
  rootCollectionId: string;
  rootHasOptions: boolean;
  sourceParentHasOptions: boolean;
}

export const MoveCollectionModal = (props: Props) => {
  const state = useUIState();
  const [modalOpen, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  const [error, setError] = createSignal("");
  const [, setFocus] = createSignal(false);
  const targetSignal = createSignal("");
  const [target] = targetSignal;
  let submitting = false;
  const onSubmit = async (event: SubmitEvent) => {
    if (!submitting) {
      submitting = true;
      const res = await props.onMove(target());
      if (res.code !== "ok") {
        setError(res.message);
      } else {
        setModalOpen("");
        setModalContents(null);
      }
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };
  const wire = useWire();
  const collections = wire.services.collections;
  const roots = () =>
    !collections.getRootsLoaded()
      ? []
      : ([CollectionKind.Favorites, CollectionKind.Org, CollectionKind.Personal].map(
        collections.getRootCollectionByKind,
      ) as CollectionSnapshot[]);

  createEffect(() => {
    if (modalOpen() === props.id) {
      setFocus(true);
    }
    if (modalOpen() === "") {
      setFocus(false);
    }
  });

  onMount(() => {
    submitting = false;
  });

  return (
    <Suspense>
      <Switch fallback={<ErrorModal title="Unable to move your Collection." errorMessage={error()} />}>
        <Match when={error() === ""}>
          <div id={props.id} class={styles["move-collection-modal"]}>
            <p class={styles["move-collection-modal__title"]}>
              <span
                class={styles["move-collection-modal__title-text"]}
              >{`Move your ${formatCollectionName(props.label)}.`}</span>
            </p>
            <menu class={styles["move-collection-modal__hierarchy"]}>
              <For each={roots().slice(VisibleCollections.OnlyPersonal)}>
                {(root) => (
                  <Show when={props.rootHasOptions}>
                    <li>
                      <CollectionsHierarchy
                        rootCollectionId={props.rootCollectionId}
                        sourceParentHasOptions={props.sourceParentHasOptions}
                        sourceParentId={props.sourceParentId ?? ""}
                        targetSignal={targetSignal}
                        sourceCollectionId={props.collectionId}
                        collectionId={root.id}
                        depth={0}
                        icon={ROOT_COLLECTION_NAMES[root.collectionKind as CollectionKind].icon ?? TbKeyframes}
                      />
                    </li>
                  </Show>
                )}
              </For>
            </menu>
            <div class={styles["move-collection-modal__buttons"]}>
              <div aria-hidden class={styles["move-collection-modal__buttons-left"]} />
              <div class={styles["move-collection-modal__buttons-right"]}>
                <PrimaryCTA
                  data-test-id="move-collections-modal-submit"
                  accessibleSuffix=""
                  label="Move Collection"
                  type="submit"
                  icon={TbArrowsMove}
                  inactive={target() === ""}
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </Match>
      </Switch>
    </Suspense>
  );
};
