import { DesktopOnly } from "~/components/containers/DesktopOnly";
import { SidebarChats } from "~/components/SidebarChats";
import { SidebarCollections } from "~/components/SidebarCollections";
import { StorytellLogo } from "~/components/StorytellLogo";
import styles from "./AlternativeSideBar.module.css";
interface Props {
  collectionId: string | undefined;
}

export const AlternativeSideBar = (props: Props) => {
  return (
    <>
      <DesktopOnly>
        <StorytellLogo spacing={true} />
      </DesktopOnly>
      <div class={styles["alternative-sidebar"]}>
        <SidebarCollections />
        <SidebarChats />
      </div>
    </>
  );
};
