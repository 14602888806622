import { stAnalytics } from "@repo/analytics";
import { type Logger, Named } from "@repo/logger";
import { captureException } from "@repo/observability";
import type { useActor } from "@xstate/solid";
import { type Accessor, createEffect, createMemo } from "solid-js";
import type { Dictionary } from "~/domains/i18n/dictionary/en";
import type { LocaleKey } from "~/domains/i18n/types/types";
import { IdentityStates, identityEventFactory } from "~/domains/identity/machines";
import { firebaseApp, firebaseAuth } from "~/domains/identity/service/firebase";
import type { AuthenticatedIdentity, IdentityStore } from "~/domains/identity/types";
import { getEmailTypeFromDomain } from "~/lib/isEmailPersonalOrBusiness";

export type IdentityServiceDependencies = {
  actor: ReturnType<typeof useActor>;
  logger: Logger;
  dictionary: Dictionary;
  locale: Accessor<LocaleKey>;
  getDeviceId: Accessor<string>;
};

export interface IdentityService {
  snapshot: {
    value: IdentityStates;
    context: IdentityStore;
  };
  createUserWithCredentials: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithMagicLink: (email: string) => Promise<void>;
  isURLMagicLink: (url: string) => boolean;
  finishSignInMagicLink: (email: string, url: string) => Promise<void>;
  signOut: () => Promise<void>;
  processOAuthRedirect: () => Promise<void>;
  getIdentityToken: () => string | undefined;
  send: ReturnType<typeof useActor>[1];
  actor: ReturnType<typeof useActor>[2];
  eventFactory: ReturnType<typeof identityEventFactory>;
  isIdentityReady: (s: IdentityStates) => boolean;
  userId: Accessor<string | undefined>;
}

export const useIdentityService = (deps: IdentityServiceDependencies): IdentityService => {
  const [snapshot, send, actor] = deps.actor;
  const logger = new Named(deps.logger, "identityService");

  const eventFactory = identityEventFactory(logger);

  actor.start();
  const fbAuth = firebaseAuth({
    getDeviceId: deps.getDeviceId,
    locale: deps.locale,
    eventFactory,
    firebase: firebaseApp(),
    logger: deps.logger,
    send,
    dictionary: deps.dictionary,
  });

  if (_LOG) {
    logger.info("service started");
    createEffect(() => {
      logger.info(`observed state change to '${snapshot.value}'`);
    });
  }

  const createUserWithCredentials = async (email: string, password: string) => {
    const flogger = logger.child("createUserWithCredentials");
    if (_LOG) flogger.info("creating user with email and password");
    try {
      await fbAuth.createUserWithCredentials(email, password);
    } catch (error) {
      flogger.error("ErrCreateUserWithCredentials", "error creating user with email and password", error);
      captureException(error);
      stAnalytics.track("error_auth", {
        email: email,
        error_source: "internal-create-user",
        flow: null,
      });
    }
  };

  const signInWithGoogle = async () => {
    const flogger = logger.child("signInWithGoogle");
    if (_LOG) flogger.info("starting");
    try {
      await fbAuth.signInWithOAuth();
    } catch (error) {
      flogger.error("ErrSignInWithGoogle", "error signing in with oauth", error);
      captureException(error);
      stAnalytics.track("error_auth", {
        email: null,
        error_source: "internal-login",
        flow: "google",
      });
    }
  };

  const signInWithMagicLink = async (email: string) => {
    const flogger = logger.child("signInWithMagicLink");
    if (_LOG) flogger.info("starting");
    try {
      await fbAuth.signInWithMagicLink(email);
    } catch (error) {
      flogger.error("ErrSignInWithMagicLink", "error signing in with magic link", error);
      captureException(error);
      stAnalytics.track("error_auth", {
        email: email,
        error_source: "internal-login",
        flow: "magic-link",
      });
    }
  };

  const isURLMagicLink = (url: string) => fbAuth.isURLMagicLink(url);

  const finishSignInMagicLink = async (email: string, url: string) => {
    const flogger = logger.child("finishSignInMagicLink");
    if (_LOG) flogger.info("starting");
    await fbAuth.finishSignInMagicLink(email, url);
  };

  const processOAuthRedirect = async () => {
    const childLogger = logger.child("processOAuthRedirect");
    try {
      await fbAuth.processOAuthRedirect();
    } catch (error) {
      childLogger.error("ErrProcessOAuthRedirect", "error processing redirect", error);
      captureException(error);
      stAnalytics.track("error_auth", {
        email: null,
        error_source: "internal-oauth-redirect",
        flow: "oauth",
      });
    }
  };

  const isIdentityReady = (s: IdentityStates): boolean => {
    const authTypeKnown = s === IdentityStates.Authenticated || s === IdentityStates.Guest;
    if (!authTypeKnown) {
      logger.info("isIdentityReady: auth type is not known");
      return false;
    }
    return true;
  };

  const signOut = async () => {
    const flogger = logger.child("signOut");
    if (_LOG) flogger.info("starting");
    try {
      await fbAuth.signOut();
    } catch (error) {
      flogger.error("ErrSignOut", "error signing out", error);
      captureException(error);
      stAnalytics.track("error_auth", {
        email: null,
        error_source: "internal-signout",
        flow: null,
      });
    }
  };

  createEffect(() => {
    if (isIdentityReady(snapshot.value) && !snapshot.context.identity.isGuest) {
      const i = snapshot.context.identity as AuthenticatedIdentity;

      stAnalytics.identify(i.userId, {
        name: i.displayName,
        email: i.email,
        emailType: getEmailTypeFromDomain(i.email),
        potentialScore: i.potentialScore,
      });
    }
  });

  const userId = createMemo(() => (snapshot.context.identity as AuthenticatedIdentity)?.userId);

  return {
    snapshot,
    send,
    actor,
    createUserWithCredentials,
    processOAuthRedirect,
    signInWithGoogle,
    signInWithMagicLink,
    isURLMagicLink,
    finishSignInMagicLink,
    signOut,
    getIdentityToken: fbAuth.getIdentityToken,
    eventFactory,
    isIdentityReady,
    userId,
  };
};
