import { Spinner } from "~/components/_original/Spinner";
import styles from "./LoadingScreen.module.css";

export const LoadingScreen = () => {
  return (
    <div class={styles["loading-screen"]}>
      <Spinner class={styles["loading-screen__spinner"]} />
    </div>
  );
};
