import { getRequestClient } from "@repo/client";
import { captureException } from "@repo/observability";
import { TbQuestionMark } from "solid-icons/tb";
import { type Component, Show, createSignal } from "solid-js";
import { StButton } from "~/components/_original/StButton";
import { StIcon } from "~/components/icons";
import { useWire } from "~/wire";
import { usePromptContext } from "../PromptContext";
import type { ChatFileUploadStrategy } from "./ChatFileUpload";

export const WebContentUpload: Component<{
  context: "thread" | "project";
  type: "youtube" | "website";
  close: (strategy: ChatFileUploadStrategy) => void;
}> = (props) => {
  const wire = useWire();
  const [error, setError] = createSignal<string | null>(null);
  const client = getRequestClient(wire.services.identity.getIdentityToken);
  const { activeCollection } = usePromptContext();

  let input!: HTMLInputElement;
  const [loading, setLoading] = createSignal(false);

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const uploadPastedContent = async (e: SubmitEvent) => {
    e.preventDefault();
    const url = input.value;

    if (!isValidUrl(url)) {
      setError("Please enter a valid URL");
      return;
    }

    setLoading(true);
    try {
      const col = activeCollection();
      if (!col) {
        throw Error("No active Collection.");
      }

      const result = await client.controlplane.CrawlURL({
        url,
        collectionID: col.id,
        organizationID: col.organizationId,
        tenantID: col.tenantId,
        processInBackground: props.context === "project",
        processInThread: props.context === "thread",
      });
      setLoading(false);
      props.close({ type: "web", assetId: result.data.id });
    } catch (e) {
      setError("Something went wrong. Please try again.");
      captureException(e);
      setLoading(false);
    }
  };

  return (
    <>
      <a
        href="https://docs.storytell.ai/features/knowledge"
        // biome-ignore lint/a11y/noBlankTarget: <explanation>
        target="_blank"
        class="text-sm underline text-white flex items-center gap-1 mb-3 -mt-2"
      >
        <StIcon icon={TbQuestionMark} />
        <span>Learn more</span>
      </a>

      <form onSubmit={uploadPastedContent}>
        <input
          disabled={loading()}
          onInput={() => setError(null)}
          ref={input}
          placeholder={props.type === "youtube" ? "Enter a YouTube link" : "Enter a website link"}
          class="mb-4 bg-transparent outline-none px-3 py-2 w-full border-2 border-indigo-700 dark:border-slate-600 hover:bg-indigo-900/30 dark:hover:bg-slate-400/5 border-dashed rounded-lg text-base leading-normal"
        />

        <div class="flex items-center justify-between gap-2">
          <div>
            <Show when={error()}>
              <span class="text-red-300 text-sm">{error()}</span>
            </Show>
          </div>

          <StButton type="submit" class="w-auto" loading={loading()} loadingLabel="Uploading">
            Upload
          </StButton>
        </div>
      </form>
    </>
  );
};
