import { stAnalytics } from "@repo/analytics";
import { useNavigate } from "@solidjs/router";
import { TbKeyframes } from "solid-icons/tb";
import { type Component, For } from "solid-js";
import { SideBarAccordion } from "~/components/SideBarAccordion";
import type { CollectionKind } from "~/domains/collections/collections.types";
import { ROOT_COLLECTION_NAMES } from "~/domains/collections/collections.ui";
import { getScreenType } from "~/lib/ui/getScreenType";
import { urls } from "~/lib/urls";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";

export const SidebarCollection: Component<{
  collectionId: string;
  depth: number;
  refreshTree: () => void;
  root?: boolean;
  onCollectionCreated: (label: string) => void;
}> = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const ui = useUIState();
  const [collectionId] = ui.collectionId;
  const [, setGettingStartedScreenManuallyHidden] = ui.gettingStartedScreenManuallyHidden;
  const url = () => urls.collection(props.collectionId);

  const collection = () => wire.services.collections.getCollection(props.collectionId);
  const rootOverride = () => ROOT_COLLECTION_NAMES[collection()?.collectionKind as CollectionKind];
  const children = () =>
    wire.services.collections
      .getChildren(props.collectionId)
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: "base", ignorePunctuation: true }));

  return (
    <SideBarAccordion
      collectionId={props.collectionId}
      root={!!props.root}
      label={rootOverride()?.label || collection()?.label || ""}
      hasChildren={children().length !== 0}
      depth={props.depth}
      icon={rootOverride()?.icon || TbKeyframes}
      initiallyOpen
      selected={collectionId() === props.collectionId}
      onMainContentClick={() => {
        setGettingStartedScreenManuallyHidden(true);
        stAnalytics.track("click_tracking", {
          cta: "navigate_to_collection",
          position: "left_drawer",
          screen: getScreenType(),
        });
        navigate(url());
      }}
      onCollectionCreated={props.onCollectionCreated}
    >
      <For each={children()}>
        {(child) => (
          <SidebarCollection
            onCollectionCreated={props.onCollectionCreated}
            collectionId={child.id}
            depth={props.depth + 1}
            refreshTree={props.refreshTree}
          />
        )}
      </For>
    </SideBarAccordion>
  );
};
