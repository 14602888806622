import { getClientTarget } from "@repo/client";
import { type Logger, Named } from "@repo/logger";
import type { useActor } from "@xstate/solid";
import { selectIsIdentityConnecting } from "~/domains/identity/hooks";
import type { useIdentityService } from "~/domains/identity/service";
import {
  type WebSocketStore,
  type WebSocketStoreExpanded,
  type WebsocketMachineStates,
  removeWebsocket,
  setWebsocket,
} from "~/domains/ws/machines";
import {
  newConnectedEvent,
  newDisconnectedEvent,
  newReceiveFromServerEvent,
} from "~/domains/ws/machines/wsEventProducers";
import { upgrade } from "~/domains/ws/service/upgrade";

export type WebsocketServiceDependencies = {
  actor: ReturnType<typeof useActor>;
  logger: Logger;
  getAuthToken: () => string | undefined;
  isIdentityConnecting: () => boolean;
};

export type WebsocketSnapshot = {
  value: WebsocketMachineStates;
  context: WebSocketStore;
};

export type WebsocketSnapshotExpanded = {
  value: WebsocketMachineStates;
  context: WebSocketStoreExpanded;
};

export interface WebsocketService {
  snapshot: WebsocketSnapshot;
  send: ReturnType<typeof useActor>[1];
  actor: ReturnType<typeof useActor>[2];
  /**
   * connect to the websocket server. This should only occur after an identity and working context have been established
   * and the token refreshed if needed. Generally, the Prompt component should be responsible for making connect
   * requests.
   */
  connect: () => void;
  disconnect: () => void;
}

/**
 * WebsocketService is a service that manages the connection to the websocket server.
 *
 * After initializing the service, you must call setServices().
 *
 * @param deps
 */
export const useWebsocketService = (deps: WebsocketServiceDependencies): WebsocketService => {
  const [snapshot, send, actor] = deps.actor;
  const logger = new Named(deps.logger, "websocketService");
  actor.start();

  if (_LOG) logger.info("service started");

  let ws: WebSocket;
  let isReady = false;

  const onMessage = (event: MessageEvent) => {
    const msg = JSON.parse(event.data);
    // logger.info("received message", { raw: event, msg });
    send(newReceiveFromServerEvent(msg));
  };

  const onOpen = () => {
    logger.info("opened connection to server");
    setWebsocket(ws);
    send(newConnectedEvent());
    isReady = true;
    connector.reset();
  };

  const onClose = (event: CloseEvent) => {
    logger.info("observed a closed websocket connection", { event });
    isReady = false;
    send(newDisconnectedEvent());
    removeWebsocket();
    disconnect();
    connector.attemptConnect();
  };

  const connector = (() => {
    let attempt = 0;
    const maxRetries = 10;
    const attemptDelay = 1_000;
    return {
      attempt,
      maxRetries,
      reset: () => {
        attempt = 0;
      },
      attemptConnect: () => {
        if (!deps.getAuthToken()) {
          logger.warn("no token found, not connecting to websocket server");
          attempt++;
          return;
        }
        if (deps.isIdentityConnecting()) {
          logger.warn("identity not ready, not connecting to websocket server");
          attempt++;
          return;
        }
        if (maxRetries <= attempt) {
          logger.warn("Max retries reached, not connecting to websocket server");
          attempt = 0;
          return;
        }
        logger.info(`trying to connect to websocket server ${attempt}/${maxRetries} attempts`);
        if (attempt === 0) {
          attempt++;
          connect();
          return;
        }
        setTimeout(() => {
          attempt++;
          connect();
        }, attemptDelay);
      },
    };
  })();

  const connect = () => {
    if (isReady) {
      logger.info("already connected to websocket server, not connecting");
      connector.reset();
      return;
    }
    const token = deps.getAuthToken();
    if (!token) {
      deps.logger.warn("wsService", "no token found, retrying connection to websocket server");
      return;
    }
    const fqURL = upgrade(getClientTarget());
    ws = new WebSocket(`${fqURL}/v1/ws?token=${token}`);
    ws.addEventListener("message", onMessage);
    ws.addEventListener("open", onOpen);
    ws.addEventListener("close", onClose);
    ws.addEventListener("error", (error) => {
      logger.warn("error connecting to websocket server", { error });
    });
    return;
  };

  // BEGIN DISCONNECTION ROUTINE -------------

  const disconnect = () => {
    logger.info("handling disconnect from websocket server");
    ws.removeEventListener("message", onMessage);
    ws.removeEventListener("open", onOpen);
    if (ws.OPEN || ws.CONNECTING) {
      ws.close();
    }
    ws.removeEventListener("close", onClose);
  };

  return {
    snapshot,
    send,
    actor,
    connect: connector.attemptConnect,
    disconnect,
  };
};
