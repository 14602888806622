import type { IconTypes } from "solid-icons";
import { BsPersonFillLock } from "solid-icons/bs";
import { OcGlobe2 } from "solid-icons/oc";
import { TbStar } from "solid-icons/tb";
import { CollectionKind } from "./collections.types";

export const ROOT_COLLECTION_NAMES: Record<CollectionKind, { label: string; icon: IconTypes }> = {
  [CollectionKind.Favorites]: { label: "Starred", icon: TbStar },
  [CollectionKind.Org]: { label: "Shared Collection", icon: OcGlobe2 },
  [CollectionKind.Personal]: { label: "Personal Collection", icon: BsPersonFillLock },
};

export enum VisibleCollections {
  All = 0,
  BothSharedAndPersonal = 1,
  OnlyPersonal = 2,
}
