import type { IconTypes } from "solid-icons";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./IconLabel.module.css";

type Modifiers = "italic" | "block";

interface Props {
  icon: IconTypes;
  label: string;
  class?: string;
  modifiers?: Modifiers[];
}

export const IconLabel = (props: Props) => {
  return (
    <p
      class={classNames(
        styles["icon-label"],
        props.modifiers ? props.modifiers.map((modifier) => styles[`icon-label--${modifier}`]).join(" ") : "",
        props.class,
      )}
    >
      <StIcon icon={props.icon} class={styles["icon-label__icon"]} size="1.125rem" />
      <span class={styles["icon-label__label"]}>{props.label}</span>
    </p>
  );
};
