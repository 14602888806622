import type { MessageInsert, ThreadMessage, curator } from "@repo/client";
import type { RouteSectionProps } from "@solidjs/router";
import { type Component, createEffect, createMemo, on, onCleanup, onMount, startTransition } from "solid-js";
import { AlternativeSideBar } from "~/components/AlternativeSideBar";
import { CollectionsTop } from "~/components/CollectionsTop";
import { ThreadTop } from "~/components/ThreadTop";
import { CollectionsModal } from "~/components/containers/CollectionsModal";
import { RightDrawer } from "~/components/containers/RightDrawer";
import { ThreadContainer } from "~/components/containers/ThreadContainer";
import { SkipToContent } from "~/components/cta/SkipToContent";
import { TwoColumnLayout } from "~/components/layouts/TwoColumnLayout";
import { SEOHeaders } from "~/components/meta/SEOHeaders";
import { PromptContextProvider } from "~/domains/chat/prompt/PromptContext";
import type { CampaingPageDataPrompt } from "~/domains/marketing/useUseCasesData";
import { CustomEvents } from "~/ui/custom-events";
import { useWire } from "~/wire";
import { ThreadPanel } from "../components/panels/ThreadPanel/ThreadPanel";
import { useAutoSubmitFileCampaign } from "../components/useAutoSubmitFileCampaign";
import { mapThreadMessagesToUIData } from "../utils/mapThreadMessagesToUIData";
export type ThreadGroup = { type: "group"; content: ThreadMessage[] };
export type ThreadUnit = {
  type: "unit";
  content: curator.MessageKnowledgeV1 | MessageInsert;
};
export type UIManagedAsset = {
  assetId: string;
  name: string;
  active: boolean;
  justChanged: boolean;
};

export const ThreadScreen: Component<{ prompt?: CampaingPageDataPrompt } & RouteSectionProps<unknown>> = (props) => {
  const wire = useWire();
  const threadsService = wire.services.threads;
  const identityService = wire.services.identity;
  const collectionsService = wire.services.collections;
  const [_threads, { updateThreadLabel }] = wire.services.threads.threadsListResource;
  const threadCollections = () => threadsService.getThreadCollections(props.params.thread_id || "") || [];
  const firstCollection = () => {
    const personal = collectionsService.getPersonalRoot();
    // This is to handle prompt campaigns, default to the personal collection
    if (props.prompt) {
      return personal;
    }
    return collectionsService.getCollection(threadCollections()[0] || "") || personal;
  };
  const [assets, { refetch: refetchAllCollectionAssets }] = wire.services.collections.resourceAllCollectionAssets(
    () => firstCollection()?.id,
  );

  // Post-processing messages and grouping them in blocks
  // Using the text instruction unit as the start of a next block
  const messages = createMemo(() =>
    mapThreadMessagesToUIData({
      messages: wire.services.threads.messages(),
      threadId: wire.services.threads.snapshot.context.threadId,
      updateThreadLabel,
    }),
  );

  const isNewThreadId = createMemo(() => props.params.thread_id === "new");

  createEffect(
    on(
      () => isNewThreadId(),
      (isNew) => {
        if (isNew) {
          wire.services.limiting.guest.resetLastInteraction();
          threadsService.send(threadsService.eventFactory.newResetEvent());
        }
      },
    ),
  );

  createEffect(() => {
    if (!identityService.snapshot.context.identity.isAuthenticated) return;
    if (isNewThreadId()) return;

    const threadId = threadsService.snapshot?.context?.threadId;
    const threadIdURL = props.params.thread_id;
    if (threadId !== threadIdURL && threadIdURL) {
      wire.services.limiting.guest.resetLastInteraction();
      threadsService.send(threadsService.eventFactory.newResumeThreadEvent(threadIdURL));
    }
  });

  onMount(() => {
    const handler = () => {
      startTransition(() => refetchAllCollectionAssets());
    };
    CustomEvents.uploadComplete.add(handler);
    onCleanup(() => {
      CustomEvents.uploadComplete.remove(handler);
    });
  });

  return (
    <PromptContextProvider
      initialPrompt={props.prompt?.prompt.name ? { content: props.prompt.prompt.name, highlight: true } : undefined}
      transformationID={props.prompt?.prompt.id}
      activeCollection={firstCollection}
      autoFocusInput
      hideBackdrop
      positioning="sticky bottom-0"
    >
      <SEOHeaders
        title={props.prompt?.prompt.name ? `${props.prompt.prompt.name} - AI prompt | Storytell.ai` : undefined}
        description={props.prompt?.prompt.summary}
      />
      <SkipToContent />
      <CollectionsModal accessibleLabel="Create Collection." />
      <RightDrawer accessibleLabel="" />
      <TwoColumnLayout
        toggleTop
        sidebar={<AlternativeSideBar collectionId={firstCollection()?.id} />}
        content={
          <>
            <CollectionsTop />
            <ThreadTop
              refetch={refetchAllCollectionAssets}
              assets={assets}
              messages={messages}
              breadcrumbs={collectionsService.getBreadcrumbs(firstCollection()?.id || "") || []}
            />
            {/* <ChatTitleBar
              label="Update this label"
              breadcrumbs={collectionsService.getBreadcrumbs(firstCollection()?.id || "") || []}
            /> */}
            <ThreadContainer>
              <ThreadPanel isNewThreadId={isNewThreadId()} messages={messages()} prompt={props.prompt} />
            </ThreadContainer>
            <AutoSubmit />
          </>
        }
      />
    </PromptContextProvider>
  );
};

// Needed as a component so that it can be inside the prompt context provider
const AutoSubmit = () => {
  useAutoSubmitFileCampaign();
  return undefined;
};
