import { A } from "@solidjs/router";
import { type Component, For, Show, createMemo } from "solid-js";
import { isServer } from "solid-js/web";
import { Motion } from "@repo/solid-motionone";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { useBreakpoints } from "~/lib/useBreakPoints";
import type { CampaignPrompt } from "../useUseCasesData";

export const LandingPagePromptCards: Component<{
  prompts: CampaignPrompt[];
  breadcrumbs: string[];
}> = (props) => {
  const breakpoints = useBreakpoints();
  const columns = createMemo(() => (breakpoints.md ? 3 : breakpoints.sm ? 2 : 1));
  const grouped = createMemo(() =>
    props.prompts.reduce(
      (a, p, i) => {
        a[i % columns()]?.push({ ...p, index: i });
        return a;
      },
      (columns() === 1 ? [[]] : columns() === 2 ? [[], []] : [[], [], []]) as (CampaignPrompt & { index: number })[][],
    ),
  );

  const fadeIn = (delay?: number) => ({
    initial: {
      opacity: isServer ? 1 : 0,
      y: isServer ? 0 : 20,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    transition: {
      delay,
    },
  });

  return (
    <div
      class="max-w-4xl tracking-wider grid gap-4 text-white mt-20 mb-32"
      classList={{
        "grid-cols-1": columns() === 1,
        "grid-cols-2": columns() === 2,
        "grid-cols-3": columns() === 3,
      }}
    >
      <For each={grouped()}>
        {(group) => (
          <div class="flex flex-col space-y-4">
            <For each={group}>
              {(prompt) => (
                <Motion.div {...fadeIn(Math.min(prompt.index, 10) * 0.05)}>
                  <A
                    href={`/${props.breadcrumbs.join("/")}/${prompt.id}`.replace("//", "/")}
                    class="group block px-4 py-4 rounded bg-indigo-1100/80 border border-indigo-900 mb-2 hover:shadow-glow-lg hover:shadow-indigo-500 transition-shadow"
                  >
                    <h2 class="transition-all text-indigo-100 group-hover:text-white mb-2 font-semibold text-lg">
                      {prompt.name}
                    </h2>

                    <div class="flex gap-4 items-center justify-between">
                      <Show when={prompt.by}>
                        <p class="text-2xs font-light text-indigo-200">By - {prompt.by?.split(",")[0]}</p>
                      </Show>
                      <button
                        class="block w-auto min-w-max min-h-max rounded bg-indigo-950 text-white ml-auto px-3 py-1"
                        type="button"
                      >
                        <Icon name={IconName.PaperAirplane} size={IconsSize.Mini} />
                      </button>
                    </div>
                  </A>
                </Motion.div>
              )}
            </For>
          </div>
        )}
      </For>
    </div>
  );
};
