export const truncatePromptArray = (
  prompt: (
    | {
        text: string;
      }
    | {
        collection: string;
        collectionId: string;
      }
  )[],
  length: number,
) => {
  let l = 0;
  const newPrompt: typeof prompt = [];
  let hasTruncated = false;

  if (!Array.isArray(prompt)) {
    return { prompt: [], hasTruncated: false };
  }

  for (const segment of prompt) {
    if (l >= length) {
      newPrompt.push({ text: "..." });
      hasTruncated = true;
      break;
    }
    if ("text" in segment) {
      if (l + segment.text.length >= length) {
        newPrompt.push({ text: `${segment.text.substring(0, length - l)}...` });
        hasTruncated = true;
        break;
      }
      l += segment.text.length;
      newPrompt.push(segment);
    } else {
      l += segment.collection.length;
      newPrompt.push(segment);
    }
  }
  return { prompt: newPrompt, hasTruncated };
};
