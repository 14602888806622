// @refresh reload
import { type AnalyticsEventPayloads, stAnalytics } from "@repo/analytics";
// @refresh reload
import { ExperimentKey, initGrowthBookClient } from "@repo/experiments";
import { captureException } from "@repo/observability";
import { Router, useLocation } from "@solidjs/router";
import { clientOnly } from "@solidjs/start";
import { FileRoutes } from "@solidjs/start/router";
import { TbReload } from "solid-icons/tb";
import { ErrorBoundary, Show, Suspense, createEffect, on, onMount } from "solid-js";
import { isServer } from "solid-js/web";
import { Layout } from "~/components/_original/Layout";
import { AuthRedirectTrigger } from "~/domains/identity/components";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import { MetaProvider } from "~/lib/solid-meta";
import "~/styles/default-theme.css";
import { UIState } from "~/ui/UIState";
import { WireProvider, useWire } from "~/wire";
import "./app.css";
import { StButton } from "./components/_original/StButton";
import { SEOHeaders } from "./components/meta/SEOHeaders";
import { parseUrlForAdgroup } from "./domains/marketing/parseUrl";
import { rebuildFullPath } from "./lib/urls";
import { AppListeners } from "./ui/AppListeners";

const DOMThirdPartyScripts = clientOnly(() => import("./components/meta/DOMThirdPartyScripts"));
export default function App() {
  /**
   * Sends data to analytics when an error boundary is triggered.
   * @see {@link https://linear.app/storytell/issue/ENG-1444/tag-errors-in-posthog-so-we-can-track-them|ENG-1444}
   */
  const trackErrorBoundary = (err: Error) => {
    const wire = useWire();
    const data: AnalyticsEventPayloads["error_boundary_triggered"] = {
      errorCode: err.name,
      errorMessage: err.message,
      email: null,
      userId: null,
    };

    if (
      wire.services.identity?.snapshot.context.identity &&
      isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)
    ) {
      data.email = wire.services.identity.snapshot.context.identity.email || null;
      data.userId = wire.services.identity.snapshot.context.identity.userId || null;
    }

    stAnalytics.track("error_boundary_triggered", data);
  };

  const Err = (props: { err: Error }) => {
    trackErrorBoundary(props.err);
    captureException(props.err);
    console.error("Error:", props.err);

    return (
      <div class="fixed inset-0 bg-white dark:bg-indigo-1100 flex flex-col items-center justify-center text-center gap-4 animate-fade-in">
        <h2 class="text-black dark:text-white text-3xl font-semibold">Oops, something went wrong!</h2>
        <p class="text-slate-800 dark:text-indigo-200">We’ve informed our engineers so they can look into it.</p>

        <StButton
          size="lg"
          onClick={() => {
            // biome-ignore lint/correctness/noSelfAssign: <explanation>
            window.location.href = window.location.href;
          }}
          icon={TbReload}
        >
          Reload
        </StButton>
        <Show when={RUNNING_ENV !== "production" && props.err && props.err instanceof Error}>
          <div class="px-20 w-screen">
            <div class="select-all rounded mt-10 max-h-96 mx-auto max-w-screen-4xl overflow-x-auto border dark:border-indigo-800 dark:bg-indigo-950  dark:text-white">
              <pre class="w-full text-left p-4 text-2xs">
                <code>{props.err?.stack}</code>
              </pre>
            </div>
          </div>
        </Show>
      </div>
    );
  };

  return (
    <Suspense>
      <MetaProvider>
        {/* Default opengraph headers */}
        <SEOHeaders
          title="Storytell.ai | Turn Data into Business Intelligence with AI"
          keywords={["storytell", "AI"]}
          description="Storytell.ai makes AI useful & secure for businesses. Turn enterprise data into actionable insights, boost team productivity, and drive smarter decisions."
          image="https://cdn.prod.website-files.com/65a80909e3cbfe6b51a9c764/65c52b7c116e90f44fc0ea58_Open%20Graph2.jpg"
        />

        {/* Routing setup */}
        <Router
          root={(props) => (
            <ErrorBoundary fallback={(err) => <Err err={err} />}>
              <PageViewTracker />

              <WireProvider>
                <UIState>
                  <AppListeners>
                    <DOMThirdPartyScripts />
                    <AuthRedirectTrigger />
                    <Layout>{props.children}</Layout>
                    {/*<Bug />*/}
                    <GrowthbookSetup />
                  </AppListeners>
                </UIState>
              </WireProvider>
            </ErrorBoundary>
          )}
        >
          <FileRoutes />
        </Router>
      </MetaProvider>
    </Suspense>
  );
}

const PageViewTracker = () => {
  const location = useLocation();
  createEffect(
    on(
      () => [location.pathname, location.search, location.hash],
      () => {
        stAnalytics.page();
        const adgroup = parseUrlForAdgroup(location);
        if (adgroup) {
          stAnalytics.plugins.posthog.setPersonProperties({ adgroup: adgroup });
          stAnalytics.track("adgroup_campaign", { adgroup_event: adgroup });
        }
      },
    ),
  );
  return undefined;
};

let client: ReturnType<typeof initGrowthBookClient> | null = null;
const GrowthbookSetup = () => {
  if (isServer) return;

  const wire = useWire();
  const experiments = wire.services.experiments.data;
  // Early returns are usually an anti-pattern because they're not reactive
  // But we don't need reactivity here, the visual experiments are either enabled
  // or disabled through the course of a session
  if (!experiments()?.[ExperimentKey.GrowthbookVisualExperiments]?.variant) {
    return null;
  }

  const location = useLocation();

  // The growthbook client should only be initialized client-side
  onMount(async () => {
    if (client) return;

    // Using requestAnimationFrame as a hacky workaround for the visual experiments running their
    // DOM changes before solidjs is finished rendering
    // If we let it run synchronously solidjs will take over the DOM right after the visual experiment
    // changes and revert it back to the original page defined in code
    requestAnimationFrame(async () => {
      client = initGrowthBookClient();

      await client.init({ timeout: 5000 });

      for (const e of client.getAllResults().values()) {
        stAnalytics.track("growthbook_experiment_exposure", {
          key: e.experiment.key,
          value: e.result.key,
        });
      }
    });
  });

  createEffect(
    on(
      () => [location.pathname, location.search, location.hash],
      () => {
        client?.setURL(rebuildFullPath(location));
      },
    ),
  );

  return undefined;
};
