import { type Component, ErrorBoundary, For, Match, Show, startTransition, Suspense, Switch } from "solid-js";
import { Spinner } from "~/components/_original/Spinner";
import { SidebarCollection } from "~/components/SideBarCollection";
import { SideBarSectionAccordion } from "~/components/SideBarSectionAccordion";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";
import { TbRefresh, TbUsersGroup } from "solid-icons/tb";
import { useWire } from "~/wire";
import { SecondaryCTA } from "./cta/SecondaryCTA";
import styles from "./SidebarCollections.module.css";

enum VisibleCollections {
  All = 0,
  BothSharedAndPersonal = 1,
  OnlyPersonal = 2,
}

export const SidebarCollections: Component = () => {
  const wire = useWire();
  const collections = wire.services.collections;
  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;

  const roots = () =>
    !collections.getRootsLoaded()
      ? []
      : ([CollectionKind.Favorites, CollectionKind.Org, CollectionKind.Personal].map(
          collections.getRootCollectionByKind,
        ) as CollectionSnapshot[]);

  const showFavorites = () => wire.services.collections.getChildrenIds(roots()[0]?.id || "").length !== 0;

  return (
    <Suspense fallback={<Spinner class="size-6" />}>
      <ErrorBoundary
        fallback={
          <div class={styles["sidebar-collections__error-boundary"]}>
            <p class={styles["sidebar-collections__error-boundary-text"]}>
              There was an error loading your collections.
            </p>
            <div>
              <SecondaryCTA
                data-test-id="sidebar-collections-error-boundary"
                accessiblePrefix="Click to "
                icon={TbRefresh}
                label="Reload"
                onClick={() => startTransition(() => refetchTree())}
              />
            </div>
          </div>
        }
      >
        <Show when={collections.getRootsLoaded() && tree()} fallback={<Spinner class="size-6" />}>
          <Show when={showFavorites()}>
            <SideBarSectionAccordion label="Starred" initiallyOpen depth={0}>
              <SidebarCollection
                onCollectionCreated={refetchTree}
                root
                collectionId={roots()[0]?.id || ""}
                depth={0}
                refreshTree={refetchTree}
              />
            </SideBarSectionAccordion>
          </Show>

          <SideBarSectionAccordion label="Collections" initiallyOpen depth={0}>
            <For each={roots().slice(VisibleCollections.OnlyPersonal)}>
              {(root) => (
                <li>
                  <SidebarCollection
                    onCollectionCreated={refetchTree}
                    root
                    collectionId={root.id}
                    depth={0}
                    refreshTree={refetchTree}
                  />
                </li>
              )}
            </For>
          </SideBarSectionAccordion>
        </Show>
      </ErrorBoundary>
    </Suspense>
  );
};
