import { stAnalytics } from "@repo/analytics";
import { TbMessagePlus } from "solid-icons/tb";
import { type Component, Show } from "solid-js";
import { CollectionBreadcrumbs } from "~/components/CollectionBreadcrumbs";
import { Anchor } from "~/components/cta/Anchor";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useCreateNewSmartchatInCollection } from "~/domains/threads/hooks/useCreateNewSmartchatInCollection";
import { getScreenType } from "~/lib/ui/getScreenType";
import styles from "./CollectionsTitleBar.module.css";

export const CollectionsTitleBar: Component<{
  label: string;
  description?: string;
  collectionId: string;
  breadcrumbs?: Breadcrumb[];
  onCollectionCreated: (label: string) => void;
}> = (props) => {
  const { activeCollection } = usePromptContext();
  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => activeCollection()?.id);
  return (
    <header data-component="rhs-title" class={styles["collections-title-bar"]}>
      <div class={styles["collections-title-bar__left"]}>
        <CollectionBreadcrumbs max={3} breadcrumbs={props.breadcrumbs || []} limited />
        <h1 class={styles["collections-title-bar__header"]}>
          <span class={styles["collections-title-bar__header-text"]}>{props.label}</span>
          <span class="screen-reader">Collection</span>
        </h1>
        <Show when={props.description}>
          <p class={styles["collections-title-bar__header-description"]}>{props.description}</p>
        </Show>
        <Show when={props.label === "Personal Collection"}>
          <p class={styles["collections-title-bar__header-docs"]}>
            <Anchor
              accessibleSuffix="Visit our documentation to"
              data-test-id="getting-started-collections-learn-more"
              class={styles["collections-title-bar__header-link"]}
              href="https://docs.storytell.ai/features/collections"
              target="_blank"
              label="Learn more about Collections."
              small
              onClick={() => {
                stAnalytics.track("click_tracking", {
                  cta: "documentation",
                  position: "main_content_title",
                  screen: getScreenType(),
                });
              }}
            />
          </p>
        </Show>
        <div class={styles["collections-title-bar__header-create"]}>
          <PrimaryCTA
            label="Chat with this Collection"
            data-test-id="thread-top-create-chat"
            icon={TbMessagePlus}
            onClick={async () => {
              stAnalytics.track("click_tracking", {
                cta: "create_chat",
                position: "main_content_title",
                screen: getScreenType(),
              });
              await onCreateSmartchat();
            }}
            accessibleSuffix="in your current Collection."
          />
        </div>

        {/* <button
          ref={(ref) => {
            setConfigRef(ref);
          }}
          class={styles.collectionsTitleBarConfig}
          type="button"
          aria-controls={configId}
        >
          <BiRegularCog class={styles.collectionsTitleBarHeaderIcon} size="1.75rem" />
          <span class="screen-reader">Configure the Collection.</span>
        </button>
        <CollectionsContextMenu
          onCollectionCreated={props.onCollectionCreated}
          bound={configRef()}
          id={configId}
          collectionId={props.collectionId}
          placement="bottom-start"
        /> */}
      </div>
      {/* <div class={styles.collectionsTitleBarRight}>
        <div class={styles.collectionsTitleBarAvatars}>
          <UserAvatarNest
            max={5}
            avatars={[
              {
                initial: "B",
                name: "Batman",
                size: "share",
              },
              {
                initial: "S",
                name: "Superman",
                size: "share",
              },
              {
                initial: "J",
                name: "Joker",
                size: "share",
              },
              {
                initial: "R",
                name: "Robin",
                size: "share",
              },
              {
                initial: "B",
                name: "Batman",
                size: "share",
              },
              {
                initial: "S",
                name: "Superman",
                size: "share",
              },
              {
                initial: "J",
                name: "Joker",
                size: "share",
              },
              {
                initial: "R",
                name: "Robin",
                size: "share",
              },
            ]}
          />
        </div>
        <ul class={styles.collectionsTitleBarMenu}>
          <li class={styles.collectionsTitleBarMenuItem}>
            <IconShareCTA
              data-test-id="title-star-collection"
              accessibleLabel="Star the Collection."
              isFavorite={true}
            />
          </li>
          <li class={styles.collectionsTitleBarMenuItem}>
            <IconCTA
              data-test-id="collections-title-action-menu"
              accessibleLabel="Share the Collection."
              icon={TbShare2}
            />
          </li>
        </ul>
      </div> */}
    </header>
  );
};
