import { TbTrash } from "solid-icons/tb";
import { Suspense, createEffect, createSignal, onMount } from "solid-js";
import type { CollectionsModalProps } from "~/components/containers/CollectionsModal";
import { DestructiveCTA } from "~/components/cta/DestruciveCTA";
import { InfoPanel } from "~/components/InfoPanel";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { useUIState } from "~/ui/UIState";
import styles from "./DeleteCollectionModal.module.css";

interface Props extends CollectionsModalProps {
  label: string;
  onDelete: () => void;
}

export const DeleteCollectionModal = (props: Props) => {
  const [modal] = useUIState().modal;
  const [, setFocus] = createSignal(false);
  let submitting = false;
  const onConfirm = () => {
    if (!submitting) {
      submitting = true;
      props.onDelete();
    }
  };

  createEffect(() => {
    if (modal() === props.id) {
      setFocus(true);
    }
    if (modal() === "") {
      setFocus(false);
    }
  });

  onMount(() => {
    submitting = false;
  });

  return (
    <Suspense>
      <p class={styles["delete-collection-modal__title"]}>
        <span class={styles["delete-collection-modal__title-text"]}>Confirm you want to continue.</span>
      </p>
      <InfoPanel
        title={`You are attempting to delete the ${formatCollectionName(props.label)}.`}
        messages={[
          "This action is irreversible.",
          "By proceeding you will permanently remove all Collection contents.",
          "This includes all sub-Collections and assets.",
        ]}
        subtext={["Please ensure you have reviewed your selection before continuing."]}
        icon={TbTrash}
        variant="danger"
      />
      <div class={styles["delete-collection-modal__buttons"]}>
        <div aria-hidden class={styles["delete-collection-modal__buttons-left"]} />
        <div class={styles["delete-collection-modal__buttons-right"]}>
          <DestructiveCTA
            data-test-id="delete-collections-modal-submit"
            accessibleSuffix=""
            label="Delete Collection"
            type="button"
            icon={TbTrash}
            onClick={onConfirm}
          />
        </div>
      </div>
    </Suspense>
  );
};
