import { stAnalytics } from "@repo/analytics/src/analytics";
import { A } from "@solidjs/router";
import { For, Show } from "solid-js";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import styles from "./CollectionBreadcrumbs.module.css";

interface Props {
  max: number;
  breadcrumbs: Breadcrumb[];
  inline?: boolean;
  limited?: boolean;
  isChat?: boolean;
}

export const CollectionBreadcrumbs = (props: Props) => {
  return (
    <Show when={(props.limited && props.breadcrumbs.length > 1) || (!props.limited && props.breadcrumbs.length > 0)}>
      <nav class={classNames(styles.breadcrumbs, props.inline ? styles["breadcrumbs--inline"] : "")}>
        <ul>
          {(() => {
            const count = props.breadcrumbs.length;
            if (count <= props.max) {
              return (
                <For each={props.breadcrumbs}>
                  {(breadcrumb) => (
                    <li class={styles.breadcrumbs__item}>
                      <A
                        href={breadcrumb.href}
                        class={styles.breadcrumbs__link}
                        tabIndex={0}
                        onClick={() => {
                          stAnalytics.track("click_tracking", {
                            cta: "navigate_to_collection",
                            position: props.isChat ? "chat_header" : "breadcrumb",
                            screen: getScreenType(),
                          });
                        }}
                      >
                        <span class="screen-reader">Go to</span>
                        <Show
                          when={breadcrumb.label.length > 100}
                          fallback={<span class={styles["breadcrumbs__link-text"]}>{breadcrumb.label}</span>}
                        >
                          <span
                            aria-hidden="true"
                            class={styles["breadcrumbs__link-text"]}
                          >{`${breadcrumb.label.slice(0, 32)}...`}</span>
                          <span class="screen-reader">{breadcrumb.label}</span>
                        </Show>
                        <span class="screen-reader">Collection.</span>
                      </A>
                    </li>
                  )}
                </For>
              );
            }

            const rendered = [
              props.breadcrumbs[0],
              {
                label: "...",
                href: "#",
              },
              ...props.breadcrumbs.slice(count - (props.max - 1)),
            ];

            return (
              <>
                <For each={rendered}>
                  {(breadcrumb) => {
                    if (breadcrumb && breadcrumb.label !== "...") {
                      return (
                        <li class={styles.breadcrumbs__item}>
                          <A
                            href={breadcrumb.href}
                            class={styles.breadcrumbs__link}
                            tabIndex={0}
                            onClick={() => {
                              stAnalytics.track("click_tracking", {
                                cta: "navigate_to_collection",
                                position: props.isChat ? "chat_header" : "breadcrumb",
                                screen: getScreenType(),
                              });
                            }}
                          >
                            <span class="screen-reader">Go to</span>
                            <Show
                              when={breadcrumb.label.length > 100}
                              fallback={<span class={styles["breadcrumbs__link-text"]}>{breadcrumb.label}</span>}
                            >
                              <span
                                aria-hidden="true"
                                class={styles["breadcrumbs__link-text"]}
                              >{`${breadcrumb.label.slice(0, 28)}...`}</span>
                              <span class="screen-reader">{breadcrumb.label}</span>
                            </Show>
                            <span class="screen-reader">Collection.</span>
                          </A>
                        </li>
                      );
                    }
                    return (
                      <li class={styles.breadcrumbs__item}>
                        <p class={styles.breadcrumbs__spacer}>
                          <span class={styles["breadcrumbs__link-text"]}>...</span>
                        </p>
                      </li>
                    );
                  }}
                </For>
              </>
            );
          })()}
        </ul>
      </nav>
    </Show>
  );
};
