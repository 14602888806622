import { stAnalytics } from "@repo/analytics";
import { BrowserStorage } from "@repo/storage";
import { useNavigate } from "@solidjs/router";
import { TbLogout } from "solid-icons/tb";
import type { Component } from "solid-js";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import { useWire } from "~/wire";
import { CircularAvatar, CircularAvatarSize } from "./CircularAvatar";
import { StDropdown } from "./StDropdown";

export const UserAvatarWithMenu: Component = () => {
  const wire = useWire();
  const navigate = useNavigate();

  const userID = () => {
    if (!isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)) {
      return undefined;
    }
    return wire.services.identity.snapshot.context.identity.userId;
  };

  const onSignOut = async () => {
    await wire.services.identity.signOut();
    navigate("/");
  };

  const items = () => {
    if (wire.services.identity.snapshot.context.identity.isGuest) {
      return [
        {
          kind: "item" as const,
          content: "Log in",
          props: {
            onClick: () => {
              stAnalytics.track("log_in_clicked", undefined);
              BrowserStorage.setSignupReferrerUrl(location.pathname);
            },
          },
        },
        {
          kind: "item" as const,
          content: "Sign up",
          props: {
            onClick: () => {
              stAnalytics.track("sign_up_clicked", undefined);
              BrowserStorage.setSignupReferrerUrl(location.pathname);
            },
          },
        },
      ];
    }
    return [
      {
        kind: "item" as const,
        content: "Sign out",
        icon: TbLogout,
        props: { onClick: onSignOut },
      },
    ];
  };

  return (
    <StDropdown
      theme="default"
      opts={{
        placement: "bottom-end",
      }}
      items={items()}
    >
      <CircularAvatar size={CircularAvatarSize.Mini} userId={userID()} />
    </StDropdown>
  );
};
