import { stAnalytics } from "@repo/analytics";
import type { projects } from "@repo/client";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { TbArchive, TbMessage2Plus } from "solid-icons/tb";
import { type Accessor, type Resource, Show, Suspense, createEffect, createSignal } from "solid-js";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useCreateNewSmartchatInCollection } from "~/domains/threads/hooks/useCreateNewSmartchatInCollection";
import type { ThreadGroup, ThreadUnit } from "~/domains/threads/screens/ThreadScreen";
import { pluralize } from "~/lib/string/pluralize";
import { getScreenType } from "~/lib/ui/getScreenType";
import { AssetsDrawerScreen } from "~/screens/drawer/AssetsDrawerScreen";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import { CollectionBreadcrumbs } from "./CollectionBreadcrumbs";
import styles from "./ThreadTop.module.css";

interface Props {
  refetch: () => void;
  assets: Resource<{ count: number; assets: Record<string, projects.AssetSnapshot[]> }>;
  messages: Accessor<(ThreadGroup | ThreadUnit)[]>;
  breadcrumbs: Breadcrumb[];
}

export const ThreadTop = (props: Props) => {
  const wire = useWire();
  const state = useUIState();
  const [, setRightDrawerContents] = state.rightDrawerContents;
  const [open, setOpen] = state.rightDrawer;
  const { activeCollection, setShowUploadModal } = usePromptContext();
  const col = () => activeCollection();
  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => activeCollection()?.id);
  const [childCollections, setChildCollections] = createSignal<string[]>([]);
  const [, setCollectionCount] = createSignal<number>(col()?.id ? childCollections().length : 1);

  createEffect((prev) => {
    const id = col()?.id;
    if (!id) return;
    if (prev !== id) {
      setChildCollections(wire.services.collections.getCollectionDescendantsIds(id));
      setCollectionCount(id ? childCollections().length : 0);
    }
  });

  return (
    <>
      <div class={styles["thread-top"]}>
        <div class={styles["thread-top__left"]}>
          <CollectionBreadcrumbs inline max={3} breadcrumbs={props.breadcrumbs || []} isChat />
        </div>
        <div class={styles["thread-top__right"]}>
          <Suspense fallback={<span class="screen-reader">0 assets</span>}>
            <Show when={props.assets()?.count} fallback={<span class="screen-reader">0 assets</span>}>
              <SecondaryCTA
                label={`${props.assets()?.count} ${pluralize(props.assets()?.count, "asset")} in scope`}
                data-test-id="thread-top-upload"
                icon={TbArchive}
                onClick={() => {
                  setRightDrawerContents(() => () => (
                    <AssetsDrawerScreen shown={open} refetch={props.refetch} assets={props.assets} />
                  ));
                  setOpen(true);
                  stAnalytics.track("click_tracking", {
                    cta: "show_chat_assets_drawer",
                    position: "chat_header",
                    screen: getScreenType(),
                  });
                }}
                accessibleSuffix="to this chat's Collection."
                inline
              />

              {/* <span class="screen-reader">and</span>
            <p class={styles["asset-count"]}>
              <StIcon icon={TbArchive} class={styles["asset-count__icon"]} size="1.125rem" aria-hidden={true} />
              <span class={styles["asset-count__text"]}>{`${props.assets()?.count} assets`}</span>
            </p> */}
              <span class={styles["thread-top__divider"]}>|</span>
            </Show>
          </Suspense>
          <SecondaryCTA
            label="Add asset"
            data-test-id="thread-top-upload"
            icon={RiSystemUploadCloud2Line}
            onClick={() => {
              setShowUploadModal(true);
              stAnalytics.track("click_tracking", {
                cta: "upload",
                position: "chat_header",
                screen: getScreenType(),
              });
            }}
            accessibleSuffix="to this chat's Collection."
            inline
          />
          <Show when={props.messages().length > 0}>
            <span class={styles["thread-top__divider"]}>|</span>
            <SecondaryCTA
              label="Start new chat"
              data-test-id="thread-top-create-chat"
              icon={TbMessage2Plus}
              onClick={() => {
                onCreateSmartchat();
                stAnalytics.track("click_tracking", {
                  cta: "create_chat",
                  position: "chat_header",
                  screen: getScreenType(),
                });
              }}
              accessibleSuffix="in your current Collection."
              inline
            />
          </Show>
        </div>
      </div>
    </>
  );
};
