import { TbCirclePlus } from "solid-icons/tb";
import { Show } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./CreateCTA.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
  modifier?: "surface"; // | "background";
}

type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

export const CreateCTA = (props: Props) => {
  return (
    <button
      type="button"
      class={classNames(
        styles["create-cta"],
        props.class,
        props.modifier ? styles[`create-cta--${props.modifier}`] : "",
      )}
      tabIndex={0}
      on:click={props.onClick}
    >
      <span class={styles["create-cta__container"]}>
        <span class={styles["create-cta__icon-container"]}>
          <TbCirclePlus class={styles["create-cta__icon"]} size="2.25rem" />
        </span>
        <Show when={props.accessiblePrefix}>
          <span class="screen-reader">{props.accessiblePrefix}</span>
        </Show>
        <span class={styles["create-cta__label"]}>{props.label}</span>
        <Show when={props.accessibleSuffix}>
          <span class="screen-reader">{props.accessibleSuffix}</span>
        </Show>
      </span>
    </button>
  );
};
