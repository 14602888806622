import { A } from "@solidjs/router";
import { type Component, For, Show } from "solid-js";
import { Icon, IconName, IconsSize } from "~/components/icons";

export const LandingBreadcrumbs: Component<{ breadcrumbs: string[] }> = (props) => (
  <div class="max-w-thread w-full text-white">
    <div class="rounded-full border border-white/20 bg-white/15 pl-2 pr-4 py-1 inline-flex items-center gap-2">
      <A href="/">
        <Icon name={IconName.Home} size={IconsSize.Micro} />
      </A>

      <span class="opacity-50 relative">
        <Icon name={IconName.ChevronRight} size={IconsSize.Micro} />
      </span>

      <For each={props.breadcrumbs}>
        {(crumb, index) => (
          <>
            <A
              href={`/${props.breadcrumbs.slice(0, index() + 1).join("/")}`}
              class="hover:underline text-sm leading-fixed"
            >
              {crumb}
            </A>
            <Show when={index() !== props.breadcrumbs.length - 1}>
              <span class="opacity-50 relative top-[1px]">
                <Icon name={IconName.ChevronRight} size={IconsSize.Micro} />
              </span>
            </Show>
          </>
        )}
      </For>
    </div>
  </div>
);
