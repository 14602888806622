import { TbArrowRight } from "solid-icons/tb";
import type { ParentComponent } from "solid-js";
import styles from "./MarketingCTA.module.css";
import { StIcon } from "../icons";
import { Dynamic } from "solid-js/web";
import { classNames } from "~/lib/classNames";
import { A } from "@solidjs/router";

export const MarketingCTA: ParentComponent<{
  href?: string;
  onClick?: () => void;
  compact?: boolean;
  noIcon?: boolean;
  class?: string;
}> = (props) => {
  return (
    <Dynamic
      component={props.href ? A : "button"}
      class={classNames(styles["ctas-main"], props.class)}
      href={props.href}
      onClick={props.onClick}
    >
      <div class={classNames(styles["ctas-main-background"], props.compact && styles["ctas-main-background--compact"])}>
        <span>{props.children}</span>
        {!props.noIcon && <StIcon icon={TbArrowRight} size={24} />}
      </div>
    </Dynamic>
  );
};
