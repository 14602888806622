import { stAnalytics } from "@repo/analytics";
import { type curator, modelDisplayName } from "@repo/client";
import {
  TbCirclePlus,
  TbEyeCog,
  TbHelpCircle,
  TbLanguage,
  TbMoodHappy,
  TbPencil,
  TbSearch,
  TbShare3,
  TbSparkles,
  TbUsers,
} from "solid-icons/tb";
import { type Accessor, type Component, For, Show } from "solid-js";
import { CopyButton } from "~/components/_original/CopyButton";
import { StButton } from "~/components/_original/StButton";
import { StDropdown, type StDropdownMenuItem } from "~/components/_original/StDropdown";
import { Tooltip, TooltipTrigger } from "~/components/_original/Tooltip";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { type AuthenticatedIdentity, isAuthenticatedIdentity } from "~/domains/identity/types";
import { MyersBriggsShortToLong, MyersBriggsTypes, responsePromptsData } from "~/lib/data";
import { languagesByPopularity } from "~/lib/data/lanugagesByPopularity";
import { cloneElementForRichText } from "~/lib/ui/cloneElementForRichText";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";
import { PromptActionsBar } from "../bars";

export const TextUnitV1ActionsBar: Component<{
  message: curator.MessageTextV1;
  ref: Accessor<HTMLElement | undefined>;
}> = (props) => {
  const wire = useWire();
  const { submitPrompt } = usePromptContext();
  const { threadEventProps } = useThreadEventProperties();

  const diagnosticsURL = async () => {
    await wire.services.threads.getSignedURLForMessageDiagnostics(
      props.message.organizationId,
      props.message.threadId,
      props.message.messageId,
    );
  };
  const showDiagnosticsBtn = () => {
    const ident = wire.services.identity.snapshot.context.identity;
    if (isAuthenticatedIdentity(ident)) {
      return ident.emailVerified && ident.email.endsWith("@storytell.ai");
    }
    return false;
  };

  const emitTracking = (action: string, extraProps?: Record<string, string>) => {
    stAnalytics.track("thread_response_action", {
      action,
      ...threadEventProps(),
      ...extraProps,
    });
  };
  const getText = () => props.message.parts.join("\n\n");

  const getFormattedText = () => {
    const element = props.ref();
    if (!element) return getText();

    const result = cloneElementForRichText(element);
    if (!result) return getText();
    return result;
  };

  const understand = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Help me understand this",
      icon: TbHelpCircle,
    },
    items: [
      {
        kind: "item",
        content: "Explain this to me like I'm five",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.explainLikeImFive(text),
              mentionedAssets: [],
            });
            emitTracking("explain_like_im_child");
          },
        },
      },
      {
        kind: "item",
        content: "Why does this matter?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.whyDoesThisMatter(text),
              mentionedAssets: [],
            });
            emitTracking("why_does_this_matter");
          },
        },
      },
    ],
  });
  const goDeeper = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Get a more in-depth answer",
      icon: TbSearch,
    },
    items: [
      {
        kind: "item",
        content: "Get more detail on this",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.getMoreDetail(text),
              mentionedAssets: [],
            });
            emitTracking("get_more_detail");
          },
        },
      },
      {
        kind: "item",
        content: "Find similar content like this",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.findMoreLikeThis(text),
              mentionedAssets: [],
            });
            emitTracking("find_similar_content");
          },
        },
      },
    ],
  });
  const rewrite = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Rewrite this for a different audience",
      icon: TbPencil,
    },
    items: [
      {
        kind: "item",
        content: "For brevity",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.rewriteForBrevity(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_brevity");
          },
        },
      },
      {
        kind: "item",
        content: "For an executive audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.rewriteForExecutive(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_executive");
          },
        },
      },
      {
        kind: "item",
        content: "For a less technical audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.rewriteForLessTechnical(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_less_technical_audience");
          },
        },
      },
      {
        kind: "item",
        content: "For a more technical audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.rewriteForMoreTechnical(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_more_technical_audience");
          },
        },
      },
      {
        kind: "menu",
        trigger: {
          content: "Rewrite so this personality type can best receive it",
          icon: TbUsers,
        },
        items: [
          {
            kind: "group",
            label: "Myers Briggs Personality Types:",
            items: MyersBriggsTypes.map((m) => ({
              kind: "item",
              content: (
                <span>
                  <span class="font-bold">{m}</span>-{MyersBriggsShortToLong(m)}
                </span>
              ),
              props: {
                class: "px-1",
                onSelect: () => {
                  const text = getText();
                  submitPrompt({
                    text: responsePromptsData.rewriteForMyersBriggs(text, m),
                    mentionedAssets: [],
                  });
                  emitTracking("rewrite_for_personality_type", {
                    personalityType: m,
                  });
                },
              },
            })),
          },
        ],
      },
    ],
  });
  const analyzeFeelings = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Analyze feelings and needs",
      icon: TbMoodHappy,
    },
    items: [
      {
        kind: "item",
        content: "What feelings and emotions were expressed?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.analyzeFeelingsEmotions(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_feelings_emotions");
          },
        },
      },
      {
        kind: "item",
        content: "What met or unmet needs were shared?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.analyzeMetUnmetNeeds(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_met_unmet_needs");
          },
        },
      },
      {
        kind: "item",
        content: "How could this have gone better?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.analyzeGoneBetter(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_gone_better");
          },
        },
      },
      {
        kind: "item",
        content: "What's really happening inside this person right now?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.analyzePersonInner(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_person_inner");
          },
        },
      },
      {
        kind: "item",
        content: "What's happening under the surface here?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = getText();
            submitPrompt({
              text: responsePromptsData.analyzeUnderSurface(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_under_surface");
          },
        },
      },
    ],
  });

  return (
    <PromptActionsBar class="justify-start gap-2 pt-0">
      <CopyButton
        simple={false}
        class="w-auto"
        showLabel
        label="Copy Answer"
        onClick={() => {
          stAnalytics.track("thread_response_copied", undefined);
        }}
        content={getFormattedText() || props.message.parts.join("\n\n")}
      />

      {/* Share button */}
      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        theme="invert"
        items={[
          {
            kind: "item",
            content: "Link to this response",
            props: {
              class: "px-2",
              onSelect: () => {
                const threadId = wire.services.threads.snapshot.context.threadId;

                // TODO - @andi previously encoded the message ID
                const url = window.location.origin + urls.threadV2(threadId ?? "");

                stAnalytics.track("thread_response_shared", {
                  url,
                  type: "copy_link",
                });
                navigator.clipboard.writeText(url);
              },
            },
          },
          {
            kind: "item",
            content: "Share via email",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                const url = `https://storytell.ai${urls.threadV2(
                  wire.services.threads.snapshot.context.threadId || "",
                )}`;

                stAnalytics.track("thread_response_shared", {
                  url,
                  type: "email",
                });
                open(
                  responsePromptsData.shareViaEmailMailtoLink({
                    text,
                    threadName: wire.services.threads.snapshot.context.label || "a thread",
                    threadUrl: url,
                    userDisplayName: (wire.services.identity.snapshot.context.identity as AuthenticatedIdentity)
                      .displayName,
                  }),
                );
              },
            },
          },
        ]}
      >
        <StButton icon={TbShare3}>Share</StButton>
      </StDropdown>

      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        theme="invert"
        items={[understand(), goDeeper(), rewrite(), analyzeFeelings()]}
      >
        <StButton simple icon={TbCirclePlus}>
          Get more from this answer
        </StButton>
      </StDropdown>

      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        containerClass="max-h-60 overflow-y-auto"
        theme="invert"
        items={languagesByPopularity.slice(0, 50).map((l) => ({
          kind: "item",
          content: `to ${l.name}`,
          props: {
            class: "px-2",
            onSelect: () => {
              const text = props.message.parts.join("\n");
              submitPrompt({
                text: responsePromptsData.translate(l.name, text),
                mentionedAssets: [],
              });
            },
          },
        }))}
      >
        <StButton simple icon={TbLanguage}>
          Translate
        </StButton>
      </StDropdown>

      <div class="flex gap-2 flex-grow justify-end items-center">
        <Tooltip
          theme="invert"
          placement="top"
          content={<ProvenanceTooltipContent provenance={props.message.provenance} />}
        >
          <TooltipTrigger class="w-auto">
            <StButton icon={TbSparkles}>{modelDisplayName(props.message.provenance.processedByModel)}</StButton>
          </TooltipTrigger>
        </Tooltip>
        <Show when={showDiagnosticsBtn()}>
          <StButton simple label="Copy Message Diagnostics Link" icon={TbEyeCog} onClick={diagnosticsURL} />
        </Show>
      </div>
    </PromptActionsBar>
  );
};

const ProvenanceTooltipContent: Component<{
  provenance: curator.Provenance;
}> = (props) => {
  const totalTokens = () => props.provenance.inputTokens + props.provenance.outputTokens;

  const enhancementText = () =>
    props.provenance.transformationId
      ? ` and enhanced by Storytell for the ${props.provenance.transformationId} use case`
      : "";

  const suggestedModel = () =>
    props.provenance.suggestedModels?.length ? props.provenance.suggestedModels[0] : undefined;

  const alternativeModels = () =>
    props.provenance.suggestedModels.slice(1, 3).map((model) => {
      const relativeScore = Math.round((100 * model.score) / (suggestedModel()?.score || 1));
      return `- ${modelDisplayName(model.model)} (${relativeScore}% as effective)`;
    });

  return (
    <p class="max-w-80">
      <Show
        when={suggestedModel()?.score && suggestedModel()?.category}
        // Fallback for when there's no suggested model score and category
        fallback={
          <span>{`Answered with ${modelDisplayName(props.provenance.processedByModel)}${enhancementText()}
            using ${totalTokens()} tokens over a secure, encrypted enterprise connection.`}</span>
        }
      >
        <span>
          {`Storytell's LLM Router chose `}
          <b>{modelDisplayName(props.provenance.processedByModel)}</b>
          {`
            to answer your ${suggestedModel()?.category.toLowerCase()} question. Other models considered were:`}
        </span>
        <br />
        <br />
        <span>
          <For each={alternativeModels()}>
            {(model) => (
              <>
                {model}
                <br />
              </>
            )}
          </For>
        </span>
        <br />
        <span>
          {`Overall score of ${suggestedModel()?.score.toFixed(2)}
            using ${totalTokens()} tokens over a secure, encrypted enterprise connection. `}
        </span>
      </Show>
      <a
        target="_blank"
        href="http://go.storytell.ai/enterprise-connection"
        class="underline underline-offset-2 whitespace-pre"
        rel="noreferrer"
      >
        [Learn More]
      </a>
    </p>
  );
};
