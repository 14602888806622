import { Match, Switch, createMemo, type Component } from "solid-js";
import { KeyboardShortcutPill } from "~/components/_original/KeyboardShortcutPill";
import { usePromptContext } from "./PromptContext";

export const ChatTips: Component = () => {
  const { focused, promptSettings, editor, isConnecting } = usePromptContext();
  const keybinding = () => (promptSettings.submitKeybinding === "enter" ? ["Shift", "Enter"] : ["Enter"]);
  const empty = createMemo(() => !!editor()?.isEmpty);
  return (
    <Switch>
      <Match when={isConnecting()}>
        <div class="hidden sm:block absolute right-12 pointer-events-none sm:text-2xs text-gray-500 dark:text-slate-400">
          <span class="animate-pulse">Connecting...</span>
        </div>
      </Match>
      <Match when={focused() && empty()}>
        <div class="hidden sm:block absolute right-12 pointer-events-none sm:text-2xs text-gray-500 dark:text-slate-400">
          <span class="animate-pulse">
            <KeyboardShortcutPill keys={keybinding()} /> for new lines
          </span>
        </div>
      </Match>
      <Match when={!focused() && empty()}>
        <div class="hidden sm:block absolute right-12 pointer-events-none sm:text-2xs text-gray-500 dark:text-slate-400">
          <span class="animate-pulse">
            <KeyboardShortcutPill keys={["Ctrl", "K"]} /> to focus on prompt
          </span>
        </div>
      </Match>
    </Switch>
  );
};
