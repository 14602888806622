import { stAnalytics } from "@repo/analytics";
import { useNavigate } from "@solidjs/router";
import { TbClockFilled, TbFolderFilled } from "solid-icons/tb";
import { type Component, For, Show, Suspense } from "solid-js";
import { CollectionContents } from "~/components/CollectionContents";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { ContentTableContainer } from "~/components/containers/ContentTableContainer";
import { StandardGrid } from "~/components/containers/StandardGrid";
import { CreateCTA } from "~/components/cta/CreateCTA";
import { LargeChatCTA } from "~/components/cta/LargeChatCTA";
import { SectionHeader } from "~/components/SectionHeader";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { useCreateNewSmartchatInCollection } from "~/domains/threads/hooks/useCreateNewSmartchatInCollection";
import type { ThreadSnapshot } from "~/domains/threads/threads.types";
import { getScreenType } from "~/lib/ui/getScreenType";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";

export const CollectionsChatsScreen: Component<{
  collection: CollectionSnapshot | undefined;
  threads: ThreadSnapshot[];
}> = (props) => {
  const navigate = useNavigate();

  const navigateToThread = (id: string) => navigate(urls.threadV2(id));

  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => props.collection?.id);

  const wire = useWire();
  const [threads] = wire.services.threads.resourceCollectionThreads(() => props.collection?.id);
  return (
    <Suspense>
      <ContentContainer>
        <SectionHeader title="Recent chats with this Collection" icon={TbClockFilled} />
        <StandardGrid columns="four">
          <CreateCTA
            data-test-id="create-new-chat"
            onClick={() => {
              stAnalytics.track("click_tracking", {
                cta: "create_chat",
                position: "main_content",
                screen: getScreenType(),
              });
              return onCreateSmartchat();
            }}
            label="New SmartChat™"
            accessibleSuffix=""
          />
          <For each={threads()?.data.result.entities.slice(0, 5) || []}>
            {(thread) => (
              <LargeChatCTA
                data-test-id="upload"
                label={thread.data.label}
                onClick={() => {
                  stAnalytics.track("click_tracking", {
                    cta: "navigate_to_chat",
                    position: "main_content",
                    screen: getScreenType(),
                  });
                  return navigateToThread(thread.data.threadId);
                }}
                accessibleSuffix="files to this Collection."
                lastModified={thread.data.modifiedAt}
              />
            )}
          </For>
        </StandardGrid>
      </ContentContainer>
      <Show when={props.threads.length}>
        <ContentTableContainer modifier="solo">
          <SectionHeader title="All chats with this Collection" icon={TbFolderFilled} />
          <CollectionContents
            onMainClick={navigateToThread}
            collectionName={props.collection?.label || ""}
            data={props.threads}
          />
        </ContentTableContainer>
      </Show>
    </Suspense>
  );
};
