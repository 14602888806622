export type Testimonial = {
  quote: string;
  by: string;
  title?: string;
  company?: string;
  image?: string;
};

export const testimonialsData: {
  featured: Testimonial;
  all: [[Testimonial[], Testimonial[]], [Testimonial[], Testimonial[]]];
} = {
  featured: {
    quote:
      "I can do in 20 seconds with Storytell what took a team of PMs months to accomplish: learn what my customers deploy to whether I was in the meeting or not. This not only changes what we build, but also opens up expansion opportunities.",
    by: "Stephen Atwell",
    title: "Product Manager",
    company: "Harness",
    image: "/images/testimonials/stephen-atwell.jpeg",
  },
  // Slightly weird json structure for the testimonials, it's done like this so we can control which testimonial goes in which column easier
  // And also to make it easier to manage the css grid

  // - first level: 1 array, 1 columns on small screens
  //  - second level: 2 arrays inside first, 2 columns on medium screens
  //   - third level: 2 arrays inside the second 2 arrays for a total of 4, 4 columns on large screens
  all: [
    [
      [
        {
          quote:
            "I realized that there's a lot of articles that I read many of which are professionally focused that I'm skimming or trying to extract manually the insights and Storytell was just so good at providing me with the punchline.",
          by: "Pierre Tapia",
          title: "Director, B2B Product Marketing",
          company: "Adobe",
          image: "/images/testimonials/pierre-tapia.png",
        },
        {
          quote:
            "The Storytell.ai feature that really impressed me was tagging. Tagging allowed me to create a particular structure around my content so that I could assemble short topics and long topics based on tags.",
          by: "Cheryl Solis",
          title: "Staff Technical Writer",
          company: "Stellate.co",
        },
      ],
      [
        {
          quote: "Storytell makes me feel like a superhero",
          by: "Grant Simmons",
          title: "VP",
          company: "Kochava Foundry",
          image: "/images/testimonials/grant-simmons.png",
        },
      ],
    ],
    [
      [
        {
          quote: "Storytell makes our meetings profitable",
          by: "Anton Belo",
          title: "VP Growth Marketing",
          company: "Kochava Foundry",
        },
      ],
      [
        {
          quote:
            'I was talking with the Storytell.ai folks about how Storytell can be helpful to lawyers and clerks to decipher the Amicus Briefs groups send. And you know what, a month prior their cofounder did this, created a chatbot where you can "Understand and Chat with US Supreme Court Decisions" how awesome is that!!!',
          by: "Johnson Hor",
          title: "Founder",
          company: "Shoebox Ventures",
          image: "/images/testimonials/johnson-hor.jpeg",
        },
        {
          quote:
            "I’m finding Storytell really helpful for my work…I used it to help me generate pitch ideas for a YouTube video based on an academic paper — and had a client accept one of those pitches!",
          by: "Samia Bouzid",
        },
      ],
    ],
  ],
};
