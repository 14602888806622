/**
 * Copy a DOM element for clipboard purposes.
 */
export const cloneElementForRichText = (element: Element) => {
  const range = document.createRange();
  range.selectNodeContents(element);

  const selection = globalThis.getSelection();
  if (!selection) return null;
  selection.removeAllRanges();
  selection.addRange(range);

  const html = range.cloneContents();
  selection.removeAllRanges();
  return html.firstElementChild as HTMLElement;
};
