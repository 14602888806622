import { TbClipboardCheck, TbClipboardCopy } from "solid-icons/tb";
import { type Component, createSignal, Show } from "solid-js";
import { StButton } from "./StButton";

export const CopyButton: Component<{
  content: string | HTMLElement;
  label: string;
  onClick?: () => void | Promise<void>;
  class?: string;
  simple?: boolean;
  showLabel?: boolean;
}> = (props) => {
  const [copied, setCopied] = createSignal(false);
  return (
    <StButton
      class={props.class}
      onClick={async () => {
        if (props.content) {
          if (typeof props.content === "string") {
            await navigator.clipboard.writeText(props.content);
          } else {
            await navigator.clipboard.write([
              new ClipboardItem({
                "text/html": new Blob([props.content.innerHTML], { type: "text/html" }),
                "text/plain": new Blob([props.content.innerText], { type: "text/plain" }),
              }),
            ]);
          }
        }

        await props.onClick?.();
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3_000);
      }}
      simple={props.simple ?? true}
      label={props.label}
      icon={copied() ? TbClipboardCheck : TbClipboardCopy}
    >
      <Show when={props.showLabel}>{props.label}</Show>
    </StButton>
  );
};
