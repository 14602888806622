/**
 * Maps relevance from 1..0 to 0%..100% (inverse and percentage)
 * @param score Value between 0 to 1, with 0 being most relevant
 */
export const formatStoryTileRelevance = (score: number): string => {
  // Handle the case where score is 0 (not relevant)
  if (score === 1) return "Not relevant";

  // Convert the score to a percentage where higher means more relevant
  // We subtract from 1 since lower scores mean higher relevance
  const percentage = Math.round(score * 100);

  // Define relevance thresholds in 5% steps
  if (percentage >= 95) return `Highly relevant (${percentage}%)`;
  if (percentage >= 90) return `Very highly relevant (${percentage}%)`;
  if (percentage >= 85) return `Very relevant (${percentage}%)`;
  if (percentage >= 80) return `Quite relevant (${percentage}%)`;
  if (percentage >= 75) return `More relevant (${percentage}%)`;
  if (percentage >= 70) return `Relevant (${percentage}%)`;
  if (percentage >= 65) return `Moderately relevant (${percentage}%)`;
  if (percentage >= 60) return `Somewhat relevant (${percentage}%)`;
  if (percentage >= 55) return `Partially relevant (${percentage}%)`;
  if (percentage >= 50) return `Less relevant (${percentage}%)`;
  if (percentage >= 45) return `Minimally relevant (${percentage}%)`;
  if (percentage >= 40) return `Slightly relevant (${percentage}%)`;
  if (percentage >= 35) return `Low relevance (${percentage}%)`;
  if (percentage >= 30) return `Very low relevance (${percentage}%)`;
  if (percentage >= 25) return `Minimal relevance (${percentage}%)`;
  if (percentage >= 20) return `Very minimal relevance (${percentage}%)`;
  if (percentage >= 15) return `Barely relevant (${percentage}%)`;
  if (percentage >= 10) return `Almost not relevant (${percentage}%)`;
  if (percentage >= 5) return `Nearly irrelevant (${percentage}%)`;
  return `Extremely low relevance (${percentage}%)`;
};
