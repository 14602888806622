import styles from "./NewHomePage.module.css";
import { createEffect, createSignal, For, onCleanup, onMount, Show } from "solid-js";
import { LandingPageNavbar } from "../components/LandingPageNavbar";
import { PromptContextProvider } from "~/domains/chat/prompt/PromptContext";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import { useWire } from "~/wire";
import { LandingHeroBackground } from "../components/LandingHeroBackground";
import { classNames } from "~/lib/classNames";
import { StIcon } from "~/components/icons";
import { TbArrowLeft, TbArrowRight } from "solid-icons/tb";
import { Accordion } from "@kobalte/core";
import campaigns from "~/domains/marketing/data/campaigns.gen.json";
import { HiSolidCheckCircle, HiSolidPaperAirplane } from "solid-icons/hi";
import { Footer } from "../components/Footer";
import { UseCasesRootHomePage } from "./UseCasesRootHomePage";
import type { CampaingPageDataLevel } from "../useUseCasesData";
import { A } from "@solidjs/router";
import { createViewportObserver, createVisibilityObserver } from "@solid-primitives/intersection-observer";
import { RiSystemShieldCheckFill, RiSystemShieldCrossFill } from "solid-icons/ri";
import { useUIState } from "~/ui/UIState";
import { BrowserStorage } from "@repo/storage";
import { usePromptParam } from "../usePromptParam";
import { MarketingCTA } from "~/components/cta/MarketingCTA";

export const NewHomePage = (props: { data: CampaingPageDataLevel }) => {
  const wire = useWire();

  const [, setIsTwoColumn] = useUIState().isTwoColumn;
  setIsTwoColumn(false);

  onMount(() => {
    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());
  });

  return (
    <div>
      <LandingPageNavbar dark />

      <main id="main-content" class={classNames(styles["main-content"], "no-fluid-typography")}>
        <Hero data={props.data} />

        <HowItWorksAnimation />

        <Divider />

        <HowItWorks />

        <Divider />

        <DataGoesToWaste />

        <Divider />

        <ScaleKnowledge />

        <Divider />

        <Collections />

        <Divider />

        <Testimonials />

        <Divider />

        <OnePlatformSuperpower />

        <Divider />

        <EnterpriseGradeSecurity />

        <Divider />

        <HowEnterpriseTeamsUseStorytell />

        <Divider />

        <StopSiftingThroughNoise />

        <Divider />

        <Footer />
      </main>
    </div>
  );
};

const Divider = () => {
  return <div class={styles.divider} />;
};

const DotsBackground = () => {
  return (
    <div class={styles["bg-dots-wrapper"]}>
      <img src="/images/home/dots-bg.avif" loading="lazy" class={styles["bg-dots"]} aria-hidden />
    </div>
  );
};

const GradientBlob = (props: { green?: boolean; bottom?: boolean; right?: boolean; left?: boolean }) => {
  return (
    <div
      class={classNames(
        styles["gradient-blob"],
        props.bottom && styles["gradient-blob--bottom"],
        props.right && styles["gradient-blob--right"],
        props.left && styles["gradient-blob--left"],
      )}
    >
      <img
        src={props.green ? "/images/home/bg-green-gradient.avif" : "/images/home/bg-purple-gradient.avif"}
        aria-hidden
      />
    </div>
  );
};

const Hero = (props: { data: CampaingPageDataLevel }) => {
  const [showBottomPrompt, setShowBottomPrompt] = createSignal(props.data.level.type !== "l0");

  const [intersectionObserver] = createViewportObserver();
  intersectionObserver;

  const wire = useWire();
  const data = [
    "support calls",
    "internal meetings",
    "market research",
    "sales reports",
    "customer feedback",
    "emails",
  ];
  const [typeIn, setTypeIn] = createSignal(data[0]);

  const { getPromptParam } = usePromptParam();
  const promptQuery = getPromptParam();

  onMount(() => {
    let currentIndex = 0;
    let keepGoing = true;
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    const typeIn = async () => {
      if (!keepGoing) return;

      setTypeIn("");
      const stack = [...(data[currentIndex] || "")];
      for (const char of stack) {
        setTypeIn((prev) => prev + char);
        await sleep(50);
      }
      currentIndex++;
      if (currentIndex >= data.length) {
        currentIndex = 0;
      }

      await sleep(4000);
      typeIn();
    };

    typeIn();
    onCleanup(() => {
      keepGoing = false;
    });
  });

  return (
    <>
      <div class={styles["hero-container"]}>
        <LandingHeroBackground heightClass="h-full" class="z-[0]" />

        <section class={styles.hero}>
          <div class={styles["hero-content"]}>
            <h1 class={styles["hero-content-title"]}>
              Cut through the noise & discover the signal in{" "}
              <span class={styles["hero-content-title-typein"]}>{typeIn()}</span>
            </h1>
            <p class={styles["hero-content-subtitle"]}>
              Let anyone on your team turn overwhelming & chaotic data into actionable insights–enabling smarter, faster
              decisions.
            </p>
          </div>

          <div use:intersectionObserver={(e) => setShowBottomPrompt(!e.isIntersecting)} class="w-full">
            <Show when={!showBottomPrompt()} fallback={<div aria-hidden class="h-[106px]" />}>
              <PromptContextProvider
                inBackground={false}
                positioning="block"
                autoFocusInput
                hideBackdrop
                activeCollection={() => wire.services.collections.getPersonalRoot()}
                initialPrompt={
                  promptQuery !== ""
                    ? {
                        content: promptQuery,
                        highlight:
                          !BrowserStorage.getIsPromptTutorialDone() &&
                          wire.services.limiting.guest.isInteractionAllowed(),
                      }
                    : undefined
                }
              >
                <PersistentPrompt class={styles["hero-prompt"]} />
              </PromptContextProvider>
            </Show>
          </div>

          <UseCasesRootHomePage {...props} />
          {/* <MainCTAs /> */}
        </section>

        <TrustedBy />
      </div>

      <Show when={showBottomPrompt()}>
        <PromptContextProvider
          inBackground={false}
          hideBackdrop
          activeCollection={() => wire.services.collections.getPersonalRoot()}
          autoFocusInput
          disableSubmit={!wire.services.identity.snapshot.context.identity.isAuthenticated}
        >
          <PersistentPrompt />
        </PromptContextProvider>
      </Show>
    </>
  );
};

const TrustedBy = () => {
  const data = [
    {
      src: "/images/home/pwc.svg",
      alt: "pwc",
    },
    {
      src: "/images/home/cribl.svg",
      alt: "Cribl",
    },
    {
      src: "/images/home/shopify.svg",
      alt: "Shopify",
    },
    {
      src: "/images/home/cloudflare.svg",
      alt: "Cloudflare",
    },
    {
      src: "/images/home/vmware.svg",
      alt: "vmware",
    },
    {
      src: "/images/home/accenture.svg",
      alt: "Accenture",
    },
    {
      src: "/images/home/kochava.avif",
      alt: "Kochava",
    },
    {
      src: "/images/home/paramount.svg",
      alt: "Paramount",
    },
    {
      src: "/images/home/VH1_logo.svg",
      alt: "VH1",
    },
    {
      src: "/images/home/bet.svg",
      alt: "Black Entertainment Television",
    },
    {
      src: "/images/home/harness.svg",
      alt: "Harness",
    },
    {
      src: "/images/home/t-mobile.svg",
      alt: "T-Mobile",
    },
    {
      src: "/images/home/clear-channel.avif",
      alt: "Clear Channel",
    },
    {
      src: "/images/home/singularity-logo.avif",
      alt: "Singularity University",
    },
  ];

  return (
    <section class={styles["trusted-by"]}>
      <h2 class={styles["trusted-by-title"]}>Trusted by users at leading companies</h2>

      <div class={styles["trusted-by-banner-container"]}>
        <div class={styles["trusted-by-banner"]}>
          <div class={styles["trusted-by-banner-logos"]}>
            <For each={data}>
              {(item) => <img class={styles["trusted-by-banner-logo"]} src={item.src} alt={item.alt} />}
            </For>
          </div>

          <div class={styles["trusted-by-banner-logos"]}>
            <For each={data}>
              {(item) => <img class={styles["trusted-by-banner-logo"]} src={item.src} alt={item.alt} />}
            </For>
          </div>
        </div>
      </div>
    </section>
  );
};

const HowItWorksAnimation = () => {
  let ref!: HTMLDivElement;
  let player!: HTMLElement;

  onMount(() => {
    const script = document.createElement("script");
    script.src = "https://unpkg.com/@dotlottie/player-component@2.7.12/dist/dotlottie-player.mjs";
    script.type = "module";

    ref.appendChild(script);

    player = document.createElement("dotlottie-player");
    player.setAttribute(
      "src",
      "https://cdn.prod.website-files.com/666d3d4d82ee72bd2cbefc8b/6776bdb51150a93d5756aa12_HR_STORYTELL-IA_04.json",
    );
    player.setAttribute("background", "transparent");
    player.classList.add(styles["how-it-works-animation"] ?? "");
    player.setAttribute("loop", "true");
    player.setAttribute("autoplay", "true");

    ref.appendChild(player);
  });

  const visible = createVisibilityObserver({ rootMargin: "0px 0px 0px 0px", threshold: 0 })(() => ref);

  createEffect(() => {
    if (visible()) {
      if ("play" in player && typeof player.play === "function") player.play();
    } else {
      if ("pause" in player && typeof player.pause === "function") player.pause();
    }
  });

  return (
    <div class={styles["how-it-works-animation-container"]} aria-hidden ref={ref}>
      <DotsBackground />
      <GradientBlob left bottom />
    </div>
  );
};

const HowItWorks = () => {
  const data = [
    {
      title: "Bring your data chaos",
      description:
        "Upload your reports, customer calls, meeting recordings & more. Storytell breaks them into Story Tiles™—bite-sized pieces of information, each capturing one concept.",
      src: "/images/home/bring-your-data.avif",
    },
    {
      title: "Chat with your data",
      description:
        "Ask questions to go deeper. Not sure what to ask? Storytell has you covered with questions tailored to your goals and will pull insights from across all your files.",
      src: "/images/home/chat.avif",
    },
    {
      title: "Uncover insights, make decisions",
      description:
        "Storytell returns insights by identifying the Story Tiles™ with the highest affinity to your question, pulling from all your files to give you exactly what you need.",
      src: "/images/home/Frame-427319888.avif",
    },
  ];
  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob green right />

      <section class={styles.section}>
        <h2 class={styles["section-title"]}>
          How <span class={styles["section-title--highlight"]}>Storytell</span> turns data into insights
        </h2>
        <p class={styles["section-subtitle"]}>Move from chaos to actionable intelligence just by chatting</p>

        <div class={styles["three-col-grid"]}>
          <For each={data}>
            {(item) => (
              <article class={styles["card-item"]}>
                <img class={styles["card-item-image"]} src={item.src} loading="lazy" alt="" />
                <div class={styles["card-item-content"]}>
                  <h3 class={styles["card-item-title"]}>{item.title}</h3>
                  <p class={styles["card-item-description"]}>{item.description}</p>
                </div>
              </article>
            )}
          </For>
        </div>
      </section>
    </div>
  );
};

const DataGoesToWaste = () => {
  const data = [
    {
      title: "Data analysis without SQL",
      description:
        "Now everyone on your team can do analysis. Just chat with Storytell to go from data to decisions in minutes—no coding or technical skills required.",
      src: "/images/home/chart-icon.svg",
    },
    {
      title: "Get insights tailored for you",
      description:
        "Storytell knows what matters most. With suggested prompts tailored to your goals, all you have to do is choose the question that best matches your needs.",
      src: "/images/home/filter-icon.svg",
    },
    {
      title: "Create impactful narratives",
      description:
        "Storytell turns everyone into an effective, data-driven storyteller. With the power of data, you and your team can do meaningful work, faster.",
      src: "/images/home/bolt-icon.svg",
    },
  ];
  return (
    <div class="relative">
      <DotsBackground />
      <section class={styles.section}>
        <h2 class={classNames(styles["section-title"], styles["section-title__max-width-lg"])}>
          80% of your business data goes to waste – Storytell turns it into insights
        </h2>
        <p class={classNames(styles["section-subtitle"], styles["section-subtitle__max-width-lg"])}>
          Storytell transforms your company’s unstructured data into accessible, actionable insights, so your team can
          analyze, decide, and succeed
        </p>

        <div class={styles["three-col-grid"]}>
          <For each={data}>
            {(item) => (
              <article class={styles["card-item"]}>
                <img class={styles["goes-to-waste-grid-image"]} src={item.src} loading="lazy" alt="" />
                <div class={styles["card-item-content"]}>
                  <h3 class={styles["card-item-title"]}>{item.title}</h3>
                  <p class={styles["card-item-description"]}>{item.description}</p>
                </div>
              </article>
            )}
          </For>
        </div>
      </section>
    </div>
  );
};

const ScaleKnowledge = () => {
  const [value, setValue] = createSignal<[string]>(["item-1"]);

  const currentItem = () => data.find((item) => item.value === value()[0]);

  const data = [
    {
      title: "Verifiable outputs you can trust",
      description:
        "Storytell doesn’t just give you answers, it gives you confidence. Each response comes with Story Tiles™, which directly reference the original data source behind the output. This means you can verify accuracy instantly ensuring the integrity of your work.",
      value: "item-1",
      src: "/images/home/storytiles-2x.avif",
    },
    {
      title: "Break down data silos",
      description:
        "Storytell unifies unstructured data from emails, meetings, reports, and more into one accessible platform—so your team doesn’t have to go searching in multiple places for the information they need.",
      value: "item-2",
      src: "/images/home/data-silos.avif",
    },
    {
      title: "Say goodbye to data blockers",
      description:
        "Don’t let information gaps slow you down. Storytell makes knowledge securely accessible, delivering instant insights so your team can move from data to decisions without delays.",
      value: "item-3",
      src: "/images/home/RBAC.avif",
    },
  ];

  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob left />
      <section class={classNames(styles.section, styles["two-col-grid"], styles["two-col-grid--centered"])}>
        <div>
          <h2 class={classNames(styles["section-title"], styles["section-title--left"])}>
            Scale knowledge. Empower teams. Drive decisions.
          </h2>
          <p class={classNames(styles["section-subtitle"], styles["section-subtitle--left"])}>
            From artificial intelligence to collaborative intelligence—Storytell.ai transforms knowledge into a shared
            asset, empowering every employee to harness the collective intelligence of your organization.
          </p>

          <Accordion.Root multiple={false} class={styles.accordion} value={value()} onChange={setValue}>
            <For each={data}>
              {(item) => (
                <Accordion.Item class={styles["accordion-item"]} value={item.value}>
                  <Accordion.Header>
                    <Accordion.Trigger>
                      <h3 class={styles["accordion-item-title"]}>{item.title}</h3>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content class={styles["accordion-item-content"]}>
                    <p class={styles["accordion-item-content-text"]}>{item.description}</p>
                  </Accordion.Content>
                  <div aria-hidden class={styles["accordion-item-gradient"]} />
                </Accordion.Item>
              )}
            </For>
          </Accordion.Root>
        </div>

        <div class="relative">
          <For each={data}>
            {(item, index) => (
              <img
                class={styles["opacity-transition"]}
                src={item.src}
                loading="lazy"
                alt=""
                style={{
                  opacity: currentItem()?.value === item.value ? 1 : 0,
                  position: index() === 0 ? "relative" : "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            )}
          </For>
        </div>
      </section>
    </div>
  );
};

const Collections = () => {
  const [value, setValue] = createSignal<[string]>(["item-1"]);

  const currentItem = () => data.find((item) => item.value === value()[0]);

  const data = [
    {
      title: "Put your data in its place",
      description:
        "Collections let you structure data into categories and subcategories, making targeted analysis straightforward. Chat across entire datasets or dive into the nitty gritty. Plus, combine multiple file types and data sources related to a particular topic.",
      value: "item-1",
      src: "/images/home/collections-menu.avif",
    },
    {
      title: "Focus your queries, refine your results",
      description:
        "Context is king. With Collections, you can ask questions within the right datasets, ensuring you always get the most relevant and actionable answers.",
      value: "item-2",
      src: "/images/home/refine-results.avif",
    },
    {
      title: "Dynamic answers that are always up to date",
      description:
        "With Dynamic SmartChats™, your answers update automatically as new data is added to Collections. Receive notifications or weekly reports to stay ahead without lifting a finger.",
      value: "item-3",
      comingSoon: true,
      src: "/images/home/dynamic-answers.avif",
    },
  ];
  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob left />

      <section
        class={classNames(
          styles.section,
          styles["two-col-grid"],
          styles["two-col-grid--centered"],
          styles["two-col-grid--reversed-mobile"],
        )}
      >
        <div class="relative">
          <For each={data}>
            {(item, index) => (
              <img
                class={styles["opacity-transition"]}
                src={item.src}
                loading="lazy"
                alt=""
                style={{
                  opacity: currentItem()?.value === item.value ? 1 : 0,
                  position: index() === 0 ? "relative" : "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            )}
          </For>
        </div>

        <div>
          <h2 class={classNames(styles["section-title"], styles["section-title--left"])}>
            Group, organize & analyze your data with Collections
          </h2>
          <p class={classNames(styles["section-subtitle"], styles["section-subtitle--left"])}>
            Storytell Collections organizes your data intelligently, letting you focus on specific sets of data, not
            just the whole pie
          </p>
          <MarketingCTA class={styles["collections-cta"]} href="https://web.storytell.ai/create-team-project-spaces">
            Learn More
          </MarketingCTA>

          <Accordion.Root multiple={false} class={styles.accordion} value={value()} onChange={setValue}>
            <For each={data}>
              {(item) => (
                <Accordion.Item class={styles["accordion-item"]} value={item.value}>
                  <Accordion.Header>
                    <Accordion.Trigger>
                      <h3 class={styles["accordion-item-title"]}>
                        {item.title}{" "}
                        <Show when={item.comingSoon}>
                          <span class={styles["coming-soon-tag"]}>Coming Soon</span>
                        </Show>
                      </h3>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content class={styles["accordion-item-content"]}>
                    <p class={styles["accordion-item-content-text"]}>{item.description}</p>
                  </Accordion.Content>
                  <div aria-hidden class={styles["accordion-item-gradient"]} />
                </Accordion.Item>
              )}
            </For>
          </Accordion.Root>
        </div>
      </section>
    </div>
  );
};

const Testimonials = () => {
  const data = [
    {
      quote:
        "“Storytell allows knowledge workers to become stronger critical thinkers, and that's missing in corporate America. Storytell handles the heavy lifting and low value tasks so my teams can focus on the analysis and critical thinking.”",
      name: "Vinnie Dela Valle",
      title: "Former SVP, Audience Impact & Intelligence",
      company: "/images/home/paramount-logo.avif",
      companyAlt: "Paramount logo",
      image: "/images/home/vinnie.avif",
    },
    {
      quote: "“Storytell is just so good at providing me the punchline.”",
      name: "Pierre Tapia",
      title: "Director, B2B Product Marketing, Adobe",
      company: "/images/home/adobe-logo.avif",
      companyAlt: "Adobe logo",
      image: "/images/home/pierre-tapia.avif",
    },
    {
      quote:
        "“Storytell does in 20 seconds what took a team of product managers months to accomplish: Answer questions like 'what cloud targets do our customers deploy to?'\n\nNow I have those answers whether I was in the customer meeting or not. This not only impacts our product roadmap but also creates deal expansion opportunities for the sales team.”",
      name: "Stephen Atwell",
      title: "Principal Product Manager",
      image: "/images/home/stephen-antwell.avif",
      company: "/images/home/harness_name_all_white.avif",
      companyAlt: "Harness logo",
    },
    {
      quote:
        "“Storytell transformed our process. What used to take two to three weeks of analysis was done in less than a day. The result? We produced a proposal that secured us a $20,000 deal—signed the very next day.”",
      name: "Grant Simmons",
      title: "VP - Kochava Foundry",
      companyAlt: "Kochava logo",
      image: "/images/home/Grant-Simmons.avif",
      company: "/images/home/kochava.avif",
    },
    {
      quote: "“Storytell reduced our time spent on writing training documents from 4 hours to just 15 minutes.”",
      name: "Phin Hochart",
      title: "Head of Product",
      companyAlt: "Zight logo",
      image: "/images/home/Phin-Hochart.jpeg",
      company: "/images/home/zight.svg",
    },
    {
      quote:
        "“With Storytell, we can leverage hours of our webinar materials to quickly create related content and resources that boost our brand halo effect.”",
      name: "Jeffrey Richardson",
      title: "Director of Product Marketing",
      companyAlt: "Kochava logo",
      image: "/images/home/Jeffrey-Richardson.webp",
      company: "/images/home/kochava1.avif",
    },
  ];

  const [active, setActive] = createSignal(0);

  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob green right />
      <section class={styles.section}>
        <div class="relative">
          <For each={data}>
            {(item, index) => (
              <div
                class={classNames(
                  styles["quote-card"],
                  styles["opacity-transition"],
                  index() !== active() && styles["quote-card--hidden"],
                )}
              >
                <div class={styles["quote-card-image-container"]}>
                  <img loading="lazy" class={styles["quote-card-image"]} src={item?.image} alt={item?.name} />
                </div>
                <div>
                  <h3 class={styles["quote-card-quote"]}>{item?.quote}</h3>
                  <div class={styles["quote-card-details"]}>
                    <div>
                      <p class={styles["quote-card-details-name"]}>{item?.name}</p>
                      <p class={styles["quote-card-details-title"]}>{item?.title}</p>
                    </div>
                    <div aria-hidden class={styles["quote-card-details-divider"]} />
                    <img
                      loading="lazy"
                      class={styles["quote-card-details-company"]}
                      src={item?.company}
                      alt={item?.company}
                    />
                  </div>
                </div>
              </div>
            )}
          </For>
        </div>

        <div class={styles["quote-card-controls"]}>
          <div class={styles["quote-card-controls-circles"]}>
            <For each={data}>
              {(_, index) => (
                <button
                  type="button"
                  onClick={() => setActive(index())}
                  class={classNames(
                    styles["quote-card-controls-circle"],
                    index() === active() && styles["quote-card-controls-circle--active"],
                  )}
                />
              )}
            </For>
          </div>

          <div class={styles["quote-card-controls-arrows"]}>
            <button
              onClick={() => setActive(active() - 1 < 0 ? data.length - 1 : active() - 1)}
              type="button"
              class={styles["quote-card-controls-arrow-left"]}
            >
              <StIcon icon={TbArrowLeft} size={24} />
            </button>
            <button
              onClick={() => setActive(active() + 1 < data.length ? active() + 1 : 0)}
              type="button"
              class={styles["quote-card-controls-arrow-right"]}
            >
              <StIcon icon={TbArrowRight} size={24} />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

const OnePlatformSuperpower = () => {
  const tabs = [
    {
      label: "Customer Success",
      title: "Faster responses, happier customers",
      description:
        "With Storytell, customer success teams can seamlessly access organized company knowledge, extract actionable insights from interactions, and automate routine tasks.\n\nStorytell helps teams resolve issues faster, improve response times, and scale support operations. Plus, with shared knowledge, Customer Success stays in sync with Sales and Product, ensuring everyone is aligned on customer needs and better equipped to deliver effective solutions.",
      src: "/images/home/customer-support.avif",
    },
    {
      label: "Executive",
      title: "Smarter insights for strategic leadership",
      description:
        "Storytell gives executives the tools to streamline decision-making and boost productivity. Instantly summarize large documents, align teams with shared insights, and automate meeting action items.\n\nWith cross-functional data at your fingertips, Storytell empowers smarter strategies and measurable results.",

      src: "/images/home/executives.avif",
    },
    {
      label: "Finance",
      title: "Streamline finance workflows with Storytell",
      description:
        "Finance teams rely on Storytell to analyze financial data, automate workflows, and boost collaboration.\n\nFrom preparing proposals to uncovering hidden trends, Storytell empowers finance professionals to work smarter, faster, and more collaboratively.",

      src: "/images/home/finance.avif",
    },
    {
      label: "Marketing",
      title: "AI that drives marketing impact",
      description:
        "With Storytell, marketing teams can transform data into creative strategies and build data-driven campaigns.\n\nExtract insights from presentations and focus groups, repurpose content, and create strategies informed by performance data. Analyze social sentiment, uncover trends, and turn your data into marketing gold with Storytell.",

      src: "/images/home/marketing.avif",
    },
    {
      label: "Operations",
      title: "Operational efficiency starts with Storytell",
      description:
        "Storytell helps operations teams analyze unstructured data, automate workflows, and align around shared knowledge.\n\nFrom creating tailored workflows to surfacing expertise across the organization, Storytell transforms routine tasks into streamlined processes.",

      src: "/images/home/operations.avif",
    },
    {
      label: "Product",
      title: "Data-driven product strategies with Storytell",
      description:
        "With Storytell, product managers can tap into customer data to uncover hidden insights and execute an informed product strategy.\n\nFrom creating PRDs to turning customer pain points into fresh product opportunities, Storytell helps you make smarter decisions faster and deliver better business outcomes.",

      src: "/images/home/prod-dev-roadmap.avif",
    },
    {
      label: "Sales",
      title: "Supercharge your sales process with Storytell",
      description:
        "With Storytell, sales teams can turn complex documents into compelling sales assets and gain deeper insights into their customer accounts. They also benefit from a shared knowledge base that keeps Product and Customer Success teams in sync for greater collaboration and alignment.\n\nFrom creating sales collateral to finding expansion opportunities, Storytell equips sales teams to close more deals.",

      src: "/images/home/customized-proposals.avif",
    },
  ];

  const [activeTab, setActiveTab] = createSignal(tabs[0]);
  return (
    <div class="relative">
      <DotsBackground />
      <section class={styles.section}>
        <div class={classNames(styles["two-col-grid"], styles["two-col-grid--bottom"])}>
          <div>
            <h2 class={classNames(styles["section-title"], styles["section-title--left"])}>
              One platform, every team’s superpower
            </h2>
            <p class={classNames(styles["section-subtitle"], styles["section-subtitle--left"])}>
              From Customer Success to Marketing to Product, Storytell helps every team uncover insights, make smarter
              decisions, and be more effective
            </p>
          </div>
          <div class={styles["one-platform-tabs"]} role="tablist">
            <For each={tabs}>
              {(item) => (
                <button
                  class={classNames(
                    styles["one-platform-tab"],
                    item.label === activeTab()?.label && styles["one-platform-tab--active"],
                  )}
                  onClick={() => setActiveTab(item)}
                  type="button"
                  role="tab"
                >
                  {item.label}
                </button>
              )}
            </For>
          </div>
        </div>

        <div class="relative">
          <For each={tabs}>
            {(item) => (
              <div
                class={classNames(
                  styles["one-platform-card"],
                  styles["opacity-transition"],
                  item.label !== activeTab()?.label && styles["one-platform-card--hidden"],
                )}
              >
                <div class={styles["one-platform-card-content"]}>
                  <h3 class={styles["one-platform-card-title"]}>{item?.title}</h3>
                  <p class={styles["one-platform-card-description"]}>{item?.description}</p>
                </div>
                <img src={item?.src} alt={item?.title} loading="lazy" />
              </div>
            )}
          </For>
        </div>
      </section>
    </div>
  );
};

const EnterpriseGradeSecurity = () => {
  const data = [
    {
      title: "No Training on Your Data",
      description: "Your data is never used to train AI models.",
      link: "https://web.storytell.ai/trust-and-security",
      icon: "/images/home/cloud-lock-icon.svg",
    },
    {
      title: "SOC2 & HIPAA",
      description: "Storytell is SOC2 Type 2 and HIPAA certified, and your data is fully encrypted, from end-to-end.",
      link: "https://trust.storytell.ai/",
      icon: "/images/home/soc2-icon.svg",
    },
    {
      title: "AI Firewall",
      comingSoon: true,
      description: "Our AI firewall separates work and personal data, so sensitive information stays private.",
      link: "https://web.storytell.ai/ai-firewall",
      icon: "/images/home/firewall-icon.svg",
    },
    {
      title: "RBAC",
      comingSoon: true,
      description: "Define who has access to your company data. Track data access for compliance and security reviews.",
      link: "https://web.storytell.ai/user-role-based-access-controls",
      icon: "/images/home/user-icon.svg",
    },
    {
      title: "Audit Logs",
      description: "Our audit logs provide visibility into who accessed what data and for what purposes.",
      link: "https://web.storytell.ai/trust-and-security",
      icon: "/images/home/clipboard-check-icon.svg",
    },
  ];

  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob left bottom />

      <section class={styles.section}>
        <h2 class={classNames(styles["section-title"], styles["section-title__max-width-lg"])}>
          Enterprise-grade security: your data’s always in your control
        </h2>
        <p class={classNames(styles["section-subtitle"], styles["section-subtitle__max-width-md"])}>
          We don’t just protect your data; we empower you to control it with enterprise-grade security and transparency
        </p>

        <div class={classNames(styles["two-col-grid"])}>
          <div class={classNames(styles["card-item"])}>
            <div class={styles["card-item-content"]}>
              <h3 class={styles["card-item-title"]}>Get the best answer every time</h3>
              <p class={styles["card-item-description"]}>
                Storytell’s LLM Router ensures every type of query gets the best-fit LLM, optimizing accuracy, speed,
                and cost.
                <br />
                <br />
                The best part? You’re always in control. Enterprise customers can override the LLM selection and take
                the reins.
              </p>
            </div>
            <div aria-hidden class="flex-auto" />
            <img class={styles["card-item-image"]} alt="" src="/images/home/best-answers.avif" />
          </div>
          <div class={classNames(styles["card-item"])}>
            <div class={styles["card-item-content"]}>
              <h3 class={styles["card-item-title"]}>Bring your own enterprise LLM to Storytell</h3>
              <p class={styles["card-item-description"]}>
                Enterprise customers can modify the LLM Router to meet specific needs—by department, team, or even
                individual users.
                <br />
                <br />
                They can also integrate their own LLMs into our LLM Router, ensuring your queries work exactly how you
                want.
              </p>
            </div>
            <div aria-hidden class="flex-auto" />
            <img class={styles["card-item-image"]} alt="" src="/images/home/own-llms.avif" />
          </div>
        </div>

        <div class={styles["security-features"]}>
          <For each={data}>
            {(item) => (
              <div class={styles["security-feature"]}>
                <div class={styles["security-feature-header"]}>
                  <div class={styles["security-feature-icon"]}>
                    <img src={item.icon} alt={item.title} />
                  </div>
                  <h3 class={styles["security-feature-title"]}>
                    {item.title}{" "}
                    <Show when={item.comingSoon}>
                      <span class={styles["coming-soon-tag"]}>Coming Soon</span>
                    </Show>
                  </h3>
                </div>
                <p class={styles["security-feature-description"]}>{item.description}</p>
                <a class={styles["security-feature-link"]} href={item.link}>
                  Learn More
                </a>
              </div>
            )}
          </For>
        </div>
      </section>
    </div>
  );
};

const HowEnterpriseTeamsUseStorytell = () => {
  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob left />

      <section class={classNames(styles.section, styles["section--full-width"])}>
        <h2
          style={{ "letter-spacing": "-2px" }}
          class={classNames(styles["section-title"], styles["section-title__max-width-md"])}
        >
          Here’s how enterprise teams are using <span class={styles["section-title--highlight"]}>Storytell</span> today
        </h2>
        <div>
          <div class={styles["prompt-loop-banner"]}>
            <For each={campaigns.prompts.slice(0, Math.floor(campaigns.prompts.length / 2))}>
              {(item) => (
                <div class={styles["prompt-loop-card"]}>
                  <h3 class={styles["prompt-loop-card-title"]}>{item.name}</h3>

                  <div class={styles["prompt-loop-card-bottom"]}>
                    <span class={styles["prompt-loop-card-bottom-author"]}>{item.by?.split(",")[0]}</span>
                    <StIcon class={styles["prompt-loop-card-bottom-icon"]} icon={HiSolidPaperAirplane} size={24} />
                  </div>
                  <A class={styles["prompt-loop-card-bottom-link"]} href={`/prompts/${item.id}`}>
                    View Prompt
                  </A>
                </div>
              )}
            </For>
          </div>
          <div class={classNames(styles["prompt-loop-banner"], styles["prompt-loop-banner--reverse"])}>
            <For each={campaigns.prompts.slice(Math.floor(campaigns.prompts.length / 2))}>
              {(item) => (
                <div class={styles["prompt-loop-card"]}>
                  <h3 class={styles["prompt-loop-card-title"]}>{item.name}</h3>

                  <div class={styles["prompt-loop-card-bottom"]}>
                    <span class={styles["prompt-loop-card-bottom-author"]}>{item.by?.split(",")[0]}</span>
                    <StIcon class={styles["prompt-loop-card-bottom-icon"]} icon={HiSolidPaperAirplane} size={24} />
                  </div>
                  <A class={styles["prompt-loop-card-bottom-link"]} href={`/prompts/${item.id}`}>
                    View Prompt
                  </A>
                </div>
              )}
            </For>
          </div>
        </div>

        <div class={styles["prompt-loop-ctas-container"]}>
          <MarketingCTA href="https://web.storytell.ai/prompts">Explore Storytell's prompt library</MarketingCTA>
        </div>
      </section>
    </div>
  );
};

const StopSiftingThroughNoise = () => {
  const tags = ["We are SOC2 Type 2 certified", "HIPAA compliant", "We don't train LLMs with your data", "AI Firewall"];
  const icons = [RiSystemShieldCheckFill, RiSystemShieldCrossFill, HiSolidCheckCircle, HiSolidCheckCircle];
  return (
    <div class="relative">
      <DotsBackground />
      <GradientBlob left bottom />
      <section class={styles.section}>
        <h2 class={classNames(styles["section-title"], styles["section-title__max-width-lg"])}>
          Stop sifting through noise & let <span class={styles["section-title--highlight-blue"]}>Storytell</span> find
          the signal
        </h2>
        <div class={styles["stop-sifting-tags"]}>
          <For each={tags}>
            {(item, index) => (
              <span class={styles["stop-sifting-tag"]}>
                <StIcon class={styles["stop-sifting-tag-icon"]} icon={icons[index()] || HiSolidCheckCircle} size={30} />
                <span class={styles["stop-sifting-tag-text"]}>{item}</span>
              </span>
            )}
          </For>
        </div>

        <div>
          <img src="/images/home/product-screenshot.avif" loading="lazy" alt="" class={styles["stop-sifting-image"]} />

          <div class={styles["stop-sifting-ctas"]}>
            <MainCTAs />
          </div>
        </div>
      </section>
    </div>
  );
};

// const Footer = () => {
//   return <footer>Footer</footer>;
// };

const MainCTAs = () => {
  return (
    <div class={styles.ctas}>
      <MarketingCTA href="/auth/signup">Get Started Now</MarketingCTA>

      <span class={styles["ctas-secondary"]}>
        or, <a href="https://web.storytell.ai/pricing">Contact Us</a>
      </span>
    </div>
  );
};
