import { BrowserStorage } from "@repo/storage";
import { useLocation } from "@solidjs/router";

export const PROMPT_PARAM_TIME_LIMIT = 1000; //  1 second in milliseconds
export const HOMEPAGE_URL_PROMPT_PARAM = "prompt";

/**
 * Custom hook to extract the prompt parameter from the URL and test for validity.
 */
export const usePromptParam = () => {
  const location = useLocation();

  /**
   * Extracts the prompt parameter from the URL.
   */
  const getValueFromURL = () => {
    const prompt = location.query[HOMEPAGE_URL_PROMPT_PARAM] as string | undefined;
    if (!prompt) return;

    return prompt;
  };

  /**
   * We limit the number of times the prompt can be used within a time frame to prevent abuse.
   */
  const isRateLimited = () => {
    const lastUsed = BrowserStorage.getDateLastUsedPromptParam();
    if (!lastUsed) return false;
    return new Date().getTime() - new Date(lastUsed).getTime() <= PROMPT_PARAM_TIME_LIMIT;
  };

  /**
   * Detects nefarious patterns in the input.
   */
  const isNefarious = (input: string): boolean => {
    const decoded = decodeURIComponent(input);

    // Regular expressions to detect common nefarious patterns
    const patterns = [
      /<script.*?>.*?<\/script.*?>/gi, // XSS: <script> tags
      /<.*?javascript:.*?>/gi, // XSS: javascript: in tags
      /<.*?on\w+=.*?>/gi, // XSS: on* attributes (e.g., onclick, onload)
    ];

    for (const pattern of patterns) {
      if (pattern.test(decoded)) {
        return true;
      }
    }

    // Reject valid JSON input.
    try {
      JSON.parse(decoded);
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Sets the prompt in the editor if it's valid.
   *
   * **Note:** We expect the editor to handle the URI decoding.
   */
  const getPromptParam = () => {
    const prompt = getValueFromURL();
    if (!prompt) return "";
    if (isRateLimited() || isNefarious(prompt)) return "";

    //  Set our last used date to prevent abuse.
    BrowserStorage.setDateLastUsedPromptParam(new Date().getTime());
    return prompt;
  };

  return { getPromptParam };
};
