import { stAnalytics } from "@repo/analytics";
import { captureException } from "@repo/observability";
import { BrowserStorage } from "@repo/storage";
import { TbBrandGoogle, TbSparkles } from "solid-icons/tb";
import { Show, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import { EmailField } from "~/components/_original/EmailField";
import { OrDivider } from "~/components/_original/OrDivider";
import { StButton } from "~/components/_original/StButton";
import { useWire } from "~/wire";

const SignUp = () => {
  const wire = useWire();

  const [state, setState] = createStore({
    email: "",
    status: "idle" as "idle" | "loading" | "sent",
  });

  const onMagicLinkSignUp = async (e: SubmitEvent) => {
    e.preventDefault();
    setState("status", "loading");

    wire.services.identity
      .signInWithMagicLink(state.email)
      .then(() => {
        setState("status", "sent");
      })
      .catch((err) => {
        console.error(err);
        captureException(err);
        stAnalytics.track("error_auth", {
          email: state.email,
          status: state.status,
          error_source: "signup",
          flow: "magic-link",
        });
        setState("status", "idle");
      });

    stAnalytics.track("sign_up_started", {
      flow: "magic-link",
      email: state.email,
    });
  };
  const onGoogleSignUp = async () => {
    try {
      // awaiting so that the redirect doesn't happen before we send out the event
      await stAnalytics.track("sign_up_started", {
        flow: "google",
      });
    } catch (error) {
      captureException(error);
      stAnalytics.track("error_auth", {
        email: state.email,
        error_source: "signup",
        flow: "google",
      });
    }
    wire.services.identity.signInWithGoogle();
  };

  onMount(() => {
    const email = BrowserStorage.getLastUsedMagicLinkEmail();
    if (email) setState("email", email);
  });

  return (
    <div class="flex flex-col items-center gap-4 sm:min-w-96">
      <div class="w-full">
        <h1 class="text-xl font-semibold bg-clip-text text-transparent bg-text-gradient-dark dark:bg-text-gradient inline">
          {state.status === "sent"
            ? "We’ve sent you a magic link!"
            : "Welcome to Storytell! Create an account to get the most out of your data"}
        </h1>
      </div>

      <Show
        when={state.status !== "sent"}
        fallback={<div class="pt-10 pb-5 w-full">Check your email — then start exploring.</div>}
      >
        <div class="w-full pb-5 pt-10">
          <p class="mb-4 leading-normal text-base">How do you want to sign up? You’ve got options</p>
          <form class="w-full flex flex-col sm:flex-row gap-4 items-end" onSubmit={onMagicLinkSignUp}>
            <EmailField
              label="Work Email"
              withIcon
              email={state.email}
              placeholder="you@work-email.com"
              setEmail={(value: string) => setState("email", value)}
              inputProps={{ autofocus: true }}
            />

            <StButton
              icon={TbSparkles}
              type="submit"
              loading={state.status === "loading"}
              disabled={state.status === "loading"}
              class="flex-shrink-0 w-full sm:w-auto"
            >
              Email me a Magic Link
            </StButton>
          </form>
        </div>

        <OrDivider />

        <div class="flex items-center gap-4 py-5">
          <StButton
            disabled={state.status === "loading"}
            onClick={onGoogleSignUp}
            iconClass="stroke-4"
            icon={TbBrandGoogle}
          >
            Sign up with Google
          </StButton>
        </div>
      </Show>
    </div>
  );
};

export default SignUp;
