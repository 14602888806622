import { IoPerson } from "solid-icons/io";
import { Match, Switch } from "solid-js";
import { classNames } from "~/lib/classNames";
import { assignAvatarColor } from "~/lib/ui/assignAvatarColor";
import styles from "./UserAvatar.module.css";
export interface AvatarProps {
  initial?: string;
  imageUrl?: string;
  isGuest?: boolean;
  name: string;
  size: "share" | "menu" | "thread" | "inline";
}
export type UserAvatarProps = Exclusive<AvatarProps, "initial" | "imageUrl" | "isGuest">;

/**
 * **UserAvatar**
 *
 * Note: Requires either `initial` **or** `imageUrl` to be provided.
 */
export const UserAvatar = (props: UserAvatarProps) => {
  return (
    <span
      class={classNames(
        styles["user-avatar"],
        !props.isGuest ? assignAvatarColor(props.name) : "",
        props.size ? styles[`user-avatar--${props.size}`] : "",
        props.isGuest ? styles["user-avatar--guest"] : "",
      )}
      title={props.name}
    >
      <Switch>
        <Match when={props.imageUrl}>
          <img src={props.imageUrl} alt={`${props.name}'s avatar image.`} />
        </Match>
        <Match when={props.initial}>
          <span class={styles["user-avatar__text"]}>{props.initial}</span>
        </Match>
        <Match when={props.isGuest}>
          <IoPerson
            class={styles["user-avatar__icon"]}
            size={props.size === "share" ? "1rem" : props.size === "menu" ? "1.125rem" : "2rem"}
          />
        </Match>
      </Switch>
    </span>
  );
};
