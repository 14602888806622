import type { IconTypes } from "solid-icons";
import { Show } from "solid-js";
import { classNames } from "~/lib/classNames";
import { StIcon } from "../icons";
import styles from "./ContextMenuItem.module.css";

export type ContextMenuItemProps = {
  divider?: boolean;
  variant?: "medium" | "small-type" | "destructive" | "primary";
  label: string;
  icon?: IconTypes;
  onClick: () => void;
};

export const ContextMenuItem = (props: ContextMenuItemProps) => {
  return (
    <li
      class={classNames(
        styles["context-menu-item"],
        props.variant ? styles[`context-menu-item--${props.variant}`] : "",
      )}
    >
      <button
        class={styles["context-menu-item__button"]}
        data-component="context-menu-item"
        type="button"
        tabIndex={0}
        onClick={props.onClick}
      >
        <Show when={props.icon}>
          <StIcon
            class={styles["context-menu-item__icon"]}
            /* biome-ignore lint/style/noNonNullAssertion: Biome not detecting <Show> condition */
            icon={props.icon!}
            size="1.125rem"
          />
        </Show>
        <span class={styles["context-menu-item__label"]}>{props.label}</span>
      </button>
    </li>
  );
};
