import { TbLock } from "solid-icons/tb";
import type { Component } from "solid-js";
import { StIcon } from "../icons";

export const EnterpriseGradePromptLink: Component<{ iconColor: string }> = (props) => {
  return (
    <div class="flex items-center justify-center gap-4">
      <a
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
        class="text-md leading-normal underline underline-offset-2"
        href="https://web.storytell.ai/"
      >
        Enterprise-grade chat
      </a>
      <StIcon icon={TbLock} class={props.iconColor} />
      <a
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
        class="text-md leading-normal underline underline-offset-2"
        href="https://web.storytell.ai/trust-and-security"
      >
        Your data is safe and secure
      </a>
    </div>
  );
};
