import { For, Show } from "solid-js";
import { testimonialsData } from "../data/testimonialsData";

const { featured, all } = testimonialsData;

export const Testimonials = () => {
  return (
    <div class="relative pb-32 pt-24 sm:pt-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-xl text-center">
          <h2 class="text-lg font-semibold leading-8 tracking-tight text-indigo-600 dark:text-indigo-300">
            Testimonials
          </h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
            What users are saying
          </p>
        </div>
        <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 dark:text-gray-100 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          {/* Featured testimonial */}
          <figure class="rounded-2xl bg-white/30 dark:bg-white/5 shadow-lg ring-1 ring-white/50 dark:ring-white/20 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote class="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 dark:text-gray-100 sm:p-12 sm:text-xl sm:leading-8">
              <p>“{featured.quote}”</p>
            </blockquote>
            <figcaption class="flex flex-wrap items-center gap-x-4 gap-y-4 px-6 py-4 sm:flex-nowrap">
              <img class="h-10 w-10 flex-none rounded-full bg-gray-50" src={featured.image} alt="" />
              <div class="flex-auto">
                <div class="font-semibold">{featured.by}</div>
                <div class="text-gray-600 dark:text-gray-300">
                  {featured.title} - {featured.company}
                </div>
              </div>
            </figcaption>
          </figure>

          <For each={all}>
            {(columnGroup, columnGroupId) => (
              <div class="space-y-8 xl:contents xl:space-y-0">
                <For each={columnGroup}>
                  {(column, columnIndex) => (
                    <div
                      class={`space-y-8 ${
                        (columnGroupId() === 0 && columnIndex() === 0) ||
                        (columnGroupId() === all.length - 1 && columnIndex() === columnGroup.length - 1)
                          ? "xl:row-span-2"
                          : "xl:row-start-1"
                      }`}
                    >
                      <For each={column}>
                        {(t) => (
                          <figure class="rounded-2xl bg-white/30 dark:bg-white/5 p-6 shadow-lg ring-1 ring-white/50 dark:ring-white/20">
                            <blockquote class="text-gray-900 dark:text-gray-100">
                              <p>{`“${t.quote}”`}</p>
                            </blockquote>
                            <figcaption class="mt-6 flex items-center gap-x-4">
                              <Show when={t.image}>
                                <img class="h-10 w-10 rounded-full bg-gray-50 flex-shrink-0" src={t.image} alt="" />
                              </Show>
                              <div>
                                <div class="font-semibold">{t.by}</div>
                                <Show when={t.title}>
                                  <div class="text-gray-600 dark:text-gray-300 text-xs">
                                    {t.title + (t.company ? ` - ${t.company}` : "")}
                                  </div>
                                </Show>
                              </div>
                            </figcaption>
                          </figure>
                        )}
                      </For>
                    </div>
                  )}
                </For>
              </div>
            )}
          </For>
        </div>
      </div>
    </div>
  );
};
