import { Dialog } from "@kobalte/core";
import { stAnalytics } from "@repo/analytics";
import { type Component, Match, Switch, createEffect, createSignal, on } from "solid-js";
import { StTabs } from "~/components/_original/StTabs";
import { Icon, IconName } from "~/components/icons";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { FileDropzone } from "~/domains/marketing/components/FileDropzone";
import { CustomEvents } from "~/ui/custom-events";
import { ChatFullScreenFileDrop } from "./ChatFullScreenFileDrop";
import { PastedContentUpload } from "./PastedContentUpload";
import { WebContentUpload } from "./WebContentUpload";

export type ChatFileUploadStrategy =
  | {
      type: "file";
    }
  | { type: "web"; assetId: string };

export const ChatFileUpload: Component<{
  open: boolean;
  setOpen: (val: boolean) => void;
  onUploaded: (strategy: ChatFileUploadStrategy) => void;
  context?: "project" | "thread";
}> = (props) => {
  const { threadEventProps } = useThreadEventProperties();
  const onUploaded = async (strategy: ChatFileUploadStrategy) => {
    props.onUploaded(strategy);
    props.setOpen(false);
    CustomEvents.uploadComplete.dispatch();
  };

  createEffect(
    on(
      () => props.open,
      (open) => {
        if (open) {
          stAnalytics.track("file_upload_modal_opened", threadEventProps());
        }
      },
    ),
  );

  const [tab, setTab] = createSignal("files");

  return (
    <>
      <Dialog.Root open={props.open} onOpenChange={props.setOpen} modal={false} preventScroll>
        <Dialog.Portal>
          <Dialog.Overlay class="fixed z-[300] inset-0 bg-indigo-1100/50" />
          <div class="fixed inset-0 z-[300] flex items-center justify-center">
            <Dialog.Content class="bg-indigo-800 w-full max-w-3xl text-white rounded-lg p-5 animate-fade-out kb-expanded:animate-fade-in">
              <div class="flex items-center justify-between gap-5">
                <Dialog.Title class="sr-only">Add knowledge</Dialog.Title>
                <StTabs
                  theme="dark"
                  tabs={[
                    {
                      label: "Upload files",
                      value: "files",
                    },
                    {
                      label: "Paste content",
                      value: "paste",
                    },
                    {
                      label: "From a website",
                      value: "web",
                    },
                    // {
                    //   label: "YouTube",
                    //   value: "youtube",
                    // },
                  ]}
                  active={tab()}
                  setTab={setTab}
                />
                <Dialog.CloseButton class="flex-none w-auto text-center dialog__close-button">
                  <Icon name={IconName.Xmark} />
                </Dialog.CloseButton>
              </div>

              <Switch>
                <Match when={tab() === "files"}>
                  <div class="mt-5">
                    <FileDropzone close={() => onUploaded({ type: "file" })} context={props.context || "thread"} />
                  </div>
                </Match>

                <Match when={tab() === "paste"}>
                  <div class="mt-5">
                    <PastedContentUpload
                      context={props.context || "thread"}
                      close={() => onUploaded({ type: "file" })}
                    />
                  </div>
                </Match>

                <Match when={tab() === "web"}>
                  <div class="mt-5">
                    <WebContentUpload type="website" context={props.context || "thread"} close={onUploaded} />
                  </div>
                </Match>
                <Match when={tab() === "youtube"}>
                  <div class="mt-5">
                    <WebContentUpload type="youtube" context={props.context || "thread"} close={onUploaded} />
                  </div>
                </Match>
              </Switch>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      <ChatFullScreenFileDrop context={props.context || "thread"} openChatFileUpload={() => props.setOpen(true)} />
    </>
  );
};
