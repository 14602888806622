import { A } from "@solidjs/router";
import type { IconTypes } from "solid-icons";
import {
  TbBook,
  TbDeviceAnalytics,
  TbDeviceGamepad2,
  TbHeartHandshake,
  TbHierarchy3,
  TbMusic,
  TbPrinter,
  TbReportMoney,
  TbSeeding,
  TbTrophy,
  TbWriting,
} from "solid-icons/tb";
import { type Component, For, Match, type Setter, Show, Switch, createEffect, createSignal, onMount } from "solid-js";
import { isServer } from "solid-js/web";
import { Bug } from "~/components/_original/Bug";
import { SEOHeaders } from "~/components/meta/SEOHeaders";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import { PromptContextProvider, usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { EditorFocusedAnimation } from "~/domains/chat/prompt/components/EditorFocusedAnimation";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import { BackgroundBlobLeft } from "../components/BackgroundBlobLeft";
import { BackgroundBlobRight } from "../components/BackgroundBlobRight";
import { CompaniesBanner } from "../components/CompaniesBanner";
import { Footer } from "../components/Footer";
import { LandingBreadcrumbs } from "../components/LandingBreadcrumbs";
import { LandingHeroBackground } from "../components/LandingHeroBackground";
import { LandingPagePromptCards } from "../components/LandingPagePromptCards";
import { Testimonials } from "../components/Testimonials";
import type { CampaignPrompt, CampaingPageDataLevel } from "../useUseCasesData";
import { LandingPageNavbar } from "../components/LandingPageNavbar";

export const fadeIn = (delay?: number) => ({
  initial: {
    opacity: isServer ? 1 : 0,
    y: isServer ? 0 : 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  transition: {
    delay,
  },
});

export const HomePageIconsMapper: Record<string, IconTypes> = {
  sales: TbReportMoney,
  marketing: TbDeviceAnalytics,
  "customer-success": TbTrophy,
  "personal-growth": TbSeeding,
  "team-dynamics": TbHeartHandshake,
  career: TbHierarchy3,
  games: TbDeviceGamepad2,
  writing: TbWriting,
  technology: TbPrinter,
  music: TbMusic,
  reading: TbBook,
};

export const HomeScreen: Component<{ data: CampaingPageDataLevel }> = (props) => {
  const wire = useWire();

  const [, setIsTwoColumn] = useUIState().isTwoColumn;
  setIsTwoColumn(false);

  const [showBottomPrompt, setShowBottomPrompt] = createSignal(props.data.level.type !== "l0");
  createEffect(() => {
    if (props.data.level.type !== "l0") {
      setShowBottomPrompt(true);
    }
  });

  onMount(() => {
    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());
  });

  const seoTitle = () => {
    const { type } = props.data.level;
    if (type === "l0") return;
    if (type === "l1") {
      if (props.data.level.id === "work") {
        return "AI Prompts for Work | Storytell.ai";
      }
      if (props.data.level.id === "life") {
        return "Useful AI Prompts for Daily Life | Storytell.ai";
      }
      if (props.data.level.id === "play") {
        return "AI Prompts for Fun | Storytell.ai";
      }
    }
    return `AI Prompts for ${props.data.level.name} | Storytell.ai`;
  };
  const seoDescription = () => {
    const { type, children } = props.data.level;
    if (type === "l0") return;

    if (type === "l1") {
      if (props.data.level.id === "work") {
        return "Access 100s of exclusive AI prompts for product managers, CEOs, marketers, customer support teams & more. Work smarter with Storytell's secure enterprise AI.";
      }
      if (props.data.level.id === "life") {
        return "Get more done every day with 100s of AI prompts for your daily life. Accomplish your goals faster, optimize your routine, and simplify tasks with Storytell.ai.";
      }
      if (props.data.level.id === "play") {
        return "Get inspired with 100s of free fun creative AI prompts. From storytelling to challenging puzzles, spark new ideas with Storytell.ai.";
      }
    }
    return children.map((c) => c.name).join(" | ");
  };

  const seoKeywords = () => {
    const { type, children } = props.data.level;
    if (type !== "l1") return;
    if (props.data.level.id === "work") {
      return ["ai for work"];
    }
    if (props.data.level.id === "life") {
      return ["ai for daily life"];
    }
    if (props.data.level.id === "play") {
      return ["ai for fun"];
    }
  };

  return (
    <div class="bg-gray-100 dark:bg-slate-950 dark:text-white isolate overflow-y-auto">
      <SEOHeaders
        // l0 is the homepage, we don't want to overwrite the generic default title/desc for the homepage
        // just for the campaigns sub-pages, i.e. /work or /life/career
        title={seoTitle()}
        description={seoDescription()}
        keywords={seoKeywords()}
      />
      <Bug />
      <LandingPageNavbar dark />

      <div class="relative min-h-[calc(100vh_+_57px)]">
        <LandingHeroBackground heightClass="h-[calc(100vh_+_57px)]" />

        <Show when={props.data.breadcrumbs.length}>
          <div class="absolute top-40 left-0 right-0 flex justify-center px-4">
            <LandingBreadcrumbs breadcrumbs={props.data.breadcrumbs} />
          </div>
        </Show>

        <UseCaseLandingPage {...props} />
      </div>

      <Show when={showBottomPrompt()}>
        <PromptContextProvider
          inBackground={false}
          hideBackdrop
          activeCollection={() => wire.services.collections.getPersonalRoot()}
          autoFocusInput
          disableSubmit={!wire.services.identity.snapshot.context.identity.isAuthenticated}
        >
          <PersistentPrompt />
        </PromptContextProvider>
      </Show>

      <CompaniesBanner />

      <div class="relative">
        <BackgroundBlobLeft />

        <Testimonials />

        <BackgroundBlobRight />
      </div>

      <div class="h-24" aria-hidden />

      <Footer />
    </div>
  );
};

// Logic needs to be in a component so that it can be added inside the PromptContext provider in RootLandingPage
// We open the question library after a second so that the user has time to visually process the rest of the page and
// notice the question library opening animation
const ShowQuestionLibraryDelayed = () => {
  const { setShowQuestionLibrary } = usePromptContext();
  onMount(() => {
    setTimeout(() => {
      setShowQuestionLibrary(true);
    }, 1000);
  });
  return null;
};

const UseCaseLandingPage: Component<{ data: CampaingPageDataLevel }> = (props) => {
  return (
    <div class="flex flex-col items-center pt-96">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-4xl text-center -mt-24">
          <h1 class="relative inline text-6xl sm:text-6xl font-semibold text-white">
            Be Limitless {props.data.level.pre} <wbr />{" "}
            <span class="relative sm:whitespace-nowrap text-transparent bg-clip-text bg-gradient-to-r from-pink-300 via-violet-300 to-cyan-300">
              {props.data.level.name}
            </span>
          </h1>
        </div>

        <Switch>
          <Match when={props.data.level.type === "l1" || props.data.level.type === "l2"}>
            <div
              class="max-w-5xl tracking-wider flex flex-col sm:flex-row flex-wrap items-center justify-center text-white mt-20"
              classList={{
                "gap-10": props.data.level.children.length > 6,
                "gap-16": props.data.level.children.length <= 6,
              }}
            >
              <For each={[...props.data.level.children].sort((a, b) => a.name.localeCompare(b.name))}>
                {(item, index) => (
                  <A
                    href={`/${props.data.breadcrumbs.join("/")}/${item.id}`.replace("//", "/")}
                    class="text-xl font-medium relative bg-indigo-1100 px-5 py-2 rounded text-white"
                  >
                    {item.name}
                    <EditorFocusedAnimation empty={true} focused={true} />
                  </A>
                )}
              </For>
            </div>

            <Show when={props.data.level.prompts}>
              <div class="mx-auto w-full flex justify-center">
                <LandingPagePromptCards
                  prompts={props.data.level.prompts as CampaignPrompt[]}
                  breadcrumbs={["prompts"]}
                />
              </div>
            </Show>
          </Match>
          <Match when={props.data.level.type === "l3"}>
            <LandingPagePromptCards
              prompts={props.data.level.children as CampaignPrompt[]}
              breadcrumbs={props.data.breadcrumbs}
            />
          </Match>
        </Switch>
      </div>
    </div>
  );
};
