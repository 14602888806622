import { type AssetLifecycleState, failedProcessingStatus, notProcessingStatus } from "@repo/client";

const processingLimit = 1000 * 60 * 15; // 15 minutes

export const isProcessingAssetState = (state: AssetLifecycleState[keyof AssetLifecycleState]): boolean => {
  return !Object.values(notProcessingStatus).includes(state as notProcessingStatus);
};

export const isFailedAssetState = (state: AssetLifecycleState[keyof AssetLifecycleState]): boolean => {
  return Object.values(failedProcessingStatus).includes(state as failedProcessingStatus);
};

export const readableProcessingState = (
  state: AssetLifecycleState[keyof AssetLifecycleState],
  modifiedAt: string | undefined,
): ProcessingReadableState => {
  if (isFailedAssetState(state)) {
    return ProcessingReadableState.Failed;
  }
  if (isProcessingAssetState(state)) {
    const date = modifiedAt ? new Date(modifiedAt) : new Date();
    const now = new Date();
    if (now.getTime() - date.getTime() > processingLimit) {
      return ProcessingReadableState.Failed;
    }
    return ProcessingReadableState.Processing;
  }
  return ProcessingReadableState.Ready;
};

export enum ProcessingReadableState {
  Ready = "Ready",
  Failed = "Failed",
  Processing = "Processing",
}
