import type { ExportImport } from "../types";
import { type Logger, Named } from "@repo/logger";
import { createSignal } from "solid-js";
import { getApiTarget, getGitHash, getPullRequestId } from "@repo/env";

export type Settings = {
  values: ExportImport;
  isServer: boolean;
};

type Dependencies = {
  isServer: boolean;
  logger: Logger;
};

export const useEnvironmentService = (deps: Dependencies) => {
  const logger = new Named(deps.logger, "environmentService");
  const values = deps.isServer ? fromServer() : fromClient();
  const gitHash = getGitHash();
  const [exceptions, setExceptions] = createSignal<string[]>([]);
  const [warnings, setWarnings] = createSignal<string[]>([]);
  logger.info("service started", { values, gitHash });
  return {
    addException: (exception: string) => {
      setExceptions((prev) => [...prev, exception]);
    },
    addWarning: (warning: string) => {
      setWarnings((prev) => [...prev, warning]);
    },
    getUserAssetBucket: (): string => {
      switch (values.apiTarget) {
        case "local":
          return "storytell-uc-assets-playground";
        case "development":
          return "storytell-uc-assets-development";
        case "preview":
          return "storytell-uc-assets-development";
        case "staging":
          return "storytell-uc-assets-staging";
        // Default to production
        case "production":
        default:
          return "storytell-uc-assets";
      }
    },
    getGCPProjectId: (): string => {
      switch (values.apiTarget) {
        case "local":
          return "storytell-playground-baba";
        case "development":
          return "storytell-development-f686";
        case "preview":
          return "storytell-development-f686";
        case "staging":
          return "storytell-staging-4f12";
        // Default to production
        case "production":
        default:
          return "storytell-production-1def";
      }
    },
    loaded: true,
    exceptions,
    warnings,
    settings: {
      isServer: deps.isServer,
      values,
    },
  };
};

export const encode = (obj: ExportImport): string => {
  return `"${btoa(JSON.stringify(obj))}"`;
};

function fromServer(): ExportImport {
  return {
    pullRequestId: getPullRequestId(),
    apiTarget: getApiTarget(),
    loaded: true,
    provider: "server",
    gitHash: getGitHash(),
  };
}

let cache: ExportImport | null = null;
function fromClient(): ExportImport {
  if (cache && Object.keys(cache).length > 0) {
    return cache;
  }
  cache = JSON.parse(atob(window.__ST_EXPORT));
  return cache as ExportImport;
}
