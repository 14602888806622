import type { AssetLifecycleState } from "@repo/client";
import { TbAlertCircle } from "solid-icons/tb";
import { TiTick } from "solid-icons/ti";
import { Dynamic, Match, Show, Switch } from "solid-js/web";
import { Spinner } from "~/components/_original/Spinner";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import { ProcessingReadableState, readableProcessingState } from "~/lib/ui/readableProcessingState";
import { type FileTypeIcon, useFileTypeIcon } from "~/ui/useFileTypeIcon";
import styles from "./InlineAsset.module.css";

interface Props {
  name: string;
  mimeType: string;
  inline?: boolean;
  class?: string;
  status?: AssetLifecycleState;
  lastModified?: string;
}

export const InlineAsset = (props: Props) => {
  const iconTheme = useFileTypeIcon(props.mimeType as FileTypeIcon);
  return (
    <p class={classNames(styles["inline-asset"], props.inline ? styles["inline-asset--inline"] : "", props.class)}>
      <span class={styles["inline-asset__icon-container"]}>
        <Dynamic component={iconTheme.icon} class={iconTheme.theme} size="1rem" />
      </span>
      <span class={styles["inline-asset__name"]}>{props.name}</span>

      <Show when={props.status && props.lastModified}>
        <Switch>
          <Match
            when={
              readableProcessingState(props.status as AssetLifecycleState, props.lastModified) ===
              ProcessingReadableState.Failed
            }
          >
            <span class={classNames(styles["inline-asset__status"], styles["inline-asset__status--failed"])}>
              <StIcon icon={TbAlertCircle} size="1.25rem" />
              <span class={styles["inline-asset__status-text"]}>
                {readableProcessingState(props.status as AssetLifecycleState, props.lastModified)}
              </span>
            </span>
          </Match>
          <Match
            when={
              readableProcessingState(props.status as AssetLifecycleState, props.lastModified) ===
              ProcessingReadableState.Processing
            }
          >
            <span class={classNames(styles["inline-asset__status"], styles["inline-asset__status--processing"])}>
              <Spinner class={styles["inline-asset__status--processing"]} />
              <span class={styles["inline-asset__status-text"]}>
                {readableProcessingState(props.status as AssetLifecycleState, props.lastModified)}
              </span>
            </span>
          </Match>
          <Match
            when={
              readableProcessingState(props.status as AssetLifecycleState, props.lastModified) ===
              ProcessingReadableState.Ready
            }
          >
            <span class={classNames(styles["inline-asset__status"], styles["inline-asset__status--ready"])}>
              <StIcon icon={TiTick} size="1.25rem" />
              <span class={styles["inline-asset__status-text"]}>
                {readableProcessingState(props.status as AssetLifecycleState, props.lastModified)}
              </span>
            </span>
          </Match>
        </Switch>
      </Show>
    </p>
  );
};
